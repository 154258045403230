/** @format */

import { useGetHome } from "../../hooks/products";
import RechargeAndTickets from "./RechargeAndTickets";
import DashboardRoutes from "./Routes";
import Statistics from "./Statistics";
import style from "./style.module.scss";

export default function HomeScreen() {
  const { data } = useGetHome();

  return (
    <div className={style.homeCont}>
      {data ? (
        <>
          <Statistics data={data} />
          <DashboardRoutes />
          <RechargeAndTickets data={data} />{" "}
        </>
      ) : null}
    </div>
  );
}
