/** @format */
"use client";
import { Input, Tabs } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Box from "../Common/Box/index";

import { useGetProducts, useSearchProducts } from "../../hooks/products";
import Prices from "./Prices";
import style from "./style.module.scss";

const { TabPane } = Tabs;
export default function Products() {
  const [searchValue, setSearch] = useState("");
  const { data, isLoading } = useGetProducts();
  const { data: searchResult, refetch } = useSearchProducts(searchValue);
  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, []);
  const debounce = <F extends (...args: any[]) => void>(
    func: F,
    delay: number
  ) => {
    let timeoutId: NodeJS.Timeout;

    return function (this: any, ...args: Parameters<F>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleInput = useCallback(
    debounce((value: string) => {
      refetch();
    }, 700),
    []
  );
  const handleChange = (value: string) => {
    setSearch(value);
    handleInput(value);
  };

  return (
    <div>
      <Box title='قائمة المنتجات'>
        <div className={style.productsCont}>
          <div className={style.filterCont}>
            {/* <Button size='large'>
              تصفية <IoMdOptions />
            </Button> */}
            <Input
              onChange={(event) => handleChange(event.target.value)}
              prefix={<CiSearch />}
              placeholder='بحث عن منتج...'
            />
          </div>
          <div className={style.tabs}>
            {/* <Tabs
              tabBarStyle={{ paddingInlineStart: "12px", margin: 0 }}
              className={style.tabCont}
              defaultActiveKey='1'>
              <TabPane
                tab={<span style={{ padding: " 12px" }}>المخزون</span>}
                key='1'>
                <Stock data={searchResult ? searchResult : data || []} />
              </TabPane>
              <TabPane
                tab={<span style={{ padding: " 12px" }}>الأسعار</span>}
                key='2'>
                <Prices data={searchResult ? searchResult : data || []} />
              </TabPane>
            </Tabs> */}
            <Prices
              data={searchResult ? searchResult : data || []}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}
