/** @format */
"use client";
import React, { useEffect } from "react";
// import { TPaymentFromCartRes } from "@/types/payment";

const CreditCardForm = () => {
  useEffect(() => {
    if (window) {
      const wpwlOptions = {
        paymentTarget: "_top",
        style: "card",
        locale: "ar",
      };

      // Assuming you have a way to access the global window object
      // in your React environment, you can set wpwlOptions on it:
      (window as any).wpwlOptions = wpwlOptions;
    }
  }, []);
  return (
    <div dir='ltr'>
      {/* <script
        async={true}
        src={`https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${}`}
      /> */}

      {/* <form
        dir='ltr'
        className='paymentWidgets'
        action={`${paymentDetails.routeName}?redirect_url=${redirectUrl}`}
        data-brands={paymentDetails.paymentBrand}
        method='POST'></form> */}
    </div>
  );
};

export default CreditCardForm;
