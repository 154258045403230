/** @format */
"use client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, Input, Select } from "antd";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useGetAllCurrencies } from "../../../hooks/lookups";
import { useGetProfile } from "../../../hooks/profile";
import { useCashReceipt, useGetBanks } from "../../../hooks/useCash";
import { TBanks } from "../../../types/products";

import ImageUploader from "../../Common/ImageUploader";
import style from "./style.module.scss";
const schema = yup.object().shape({
  bank_id: yup.number().required("Bank is required"),
  transferring_name: yup.string().required("Name is required"),
  amount: yup
    .number()
    .typeError("Total must be a number")
    .positive("Total must be greater than zero")
    .required("Total is required"),
  transferring_account_number: yup
    .number()
    .typeError("Account Number must be a number")
    .positive("Account Number must be greater than zero")
    .required("Account Number is required"),
  notes: yup.string(),
  currency_id: yup.number().required("Currency is required"),
  receipt_image: yup
    .mixed()
    .required("You need to upload a file")
    .test(
      "يجب أن يكون receipt image ملف من النوع: jpeg, png, jpg, pdf.",
      (value) => {
        return (
          value &&
          value instanceof File &&
          ["image/jpeg", "image/png", "image/jpg", "image/pdf"].includes(
            value.type
          )
        );
      }
    ),
});
const { TextArea } = Input;

export default function Cash() {
  const navigate = useNavigate();
  const [selectedBank, setBank] = useState<TBanks>();
  const [confirmation, setConfirmation] = useState(false);
  const [receipt_image, setImage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: currencies } = useGetAllCurrencies();
  const { data: profile } = useGetProfile();
  const { data: banks } = useGetBanks();
  const { data, mutate, isLoading } = useCashReceipt(navigate);
  const onSubmit = (data: any) => {
    if (confirmation) {
      const formData = new FormData();

      Object.keys(data).forEach((key: any) => {
        formData.append(key, data[key]);
      });
      formData.append("recharge_balance_type", "cash");

      formData.append("notes", data.notes || "");
      mutate(formData);
    }
  };
  const saveImage = (image: string | null, file: File) => {
    setValue("receipt_image", file);
    setImage(image);
  };
  return (
    <div className={style.cont}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.cashCont}>
          <div className={style.formCont}>
            <div className={style.form}>
              <div>
                <label>البنك</label>

                <Controller
                  name='bank_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder=' أختر البنك'
                      size='large'
                      {...field}
                      onChange={(bankId) => {
                        setBank(
                          banks?.find((bank) => Number(bankId) === bank.id)
                        );
                        setValue("bank_id", bankId);
                      }}
                      style={{ width: "100%" }}
                      options={[
                        {
                          label: <span>كل الأقسام </span>,
                          title: "أختر البنك ",
                          options: banks
                            ? banks.map((brand) => ({
                                label: <span>{brand.name}</span>,
                                value: brand.id,
                              }))
                            : [],
                        },
                      ]}
                      showSearch
                    />
                  )}
                />
                {errors.bank_id?.message ? (
                  <span className={style.error}>{errors.bank_id?.message}</span>
                ) : null}
              </div>
              <div>
                <label> بيانات الحساب البنكي</label>
                <div className={style.bankDetails}>
                  <span>اسم الحساب : {selectedBank?.name || "-"}</span>
                  <span>
                    رقم الحساب : {selectedBank?.account_number || "-"}
                  </span>
                  <span>
                    تفاصيل الحساب : {selectedBank?.description || "-"}
                  </span>
                  <span>IBAN : {selectedBank?.iban_number || "-"}</span>
                </div>
              </div>
              <div>
                <label>اسم المحول</label>
                <Controller
                  name='transferring_name'
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder='اكتب اسم المحول'
                      size='large'
                      {...field}
                    />
                  )}
                />
                {errors.transferring_name?.message ? (
                  <span className={style.error}>
                    {errors.transferring_name?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label> رقم حساب المحول</label>
                <Controller
                  name='transferring_account_number'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      size='large'
                      {...field}
                      placeholder='ادخل رقم الحساب'
                      // addonAfter={profile?.currency?.name || " ريال سعودي"}
                      // type='transferring_account_number'
                    />
                  )}
                />
                {errors.amount?.message ? (
                  <span className={style.error}>{errors.amount?.message}</span>
                ) : null}
              </div>
              <div>
                <label>قيمة الرصيد</label>
                <Controller
                  name='amount'
                  control={control}
                  render={({ field }) => (
                    <Input
                      size='large'
                      {...field}
                      placeholder='ادخل قيمة الرصيد'
                      // addonAfter={profile?.currency?.name || " ريال سعودي"}
                      type='number'
                    />
                  )}
                />
                {errors.amount?.message ? (
                  <span className={style.error}>{errors.amount?.message}</span>
                ) : null}
              </div>
              <div>
                <label>العمله</label>
                <Controller
                  name='currency_id'
                  control={control}
                  render={({ field }) => (
                    <Select
                      onChange={(event) => setValue("currency_id", event)}
                      placeholder='أختر العمله'
                      options={currencies?.map((currency) => ({
                        label: currency.name,
                        value: currency.id,
                      }))}
                    />
                  )}
                />
                {errors.transferring_name?.message ? (
                  <span className={style.error}>
                    {errors.transferring_name?.message}
                  </span>
                ) : null}
              </div>
              <div>
                <label>ملاحظات إضافية</label>
                <Controller
                  name='notes'
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      maxLength={100}
                      placeholder='اكتب ملاحظاتك'
                    />
                  )}
                />
              </div>
              <Checkbox
                value={confirmation}
                onChange={() => setConfirmation(!confirmation)}>
                أوكد قيامي بالتحويل البنكي قبل تأكيد الطلب
              </Checkbox>
            </div>
          </div>
          <div className={style.receiptCont}>
            <p>صورة الإيصال</p>
            <ImageUploader setImage={saveImage} image={receipt_image} />
            {errors.receipt_image?.message ? (
              <span className={style.error}>
                {errors.receipt_image?.message}
              </span>
            ) : null}
          </div>
        </div>
        <Button
          loading={isLoading}
          variant='filled'
          htmlType='submit'
          color='default'>
          ارسال
        </Button>
      </form>
    </div>
  );
}
