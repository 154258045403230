/** @format */

import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import {
  TForgetPassword,
  TGoogleVerify,
  TResetPassword,
  TSignIn,
  TSignUp,
  TSignUpRes,
  TUser,
} from "../types/auth";
import api from "./api";

const authService = {
  signIn: async (body: TSignIn): Promise<AxiosResponse<TResponse<TUser>>> =>
    api.post("/login", body),

  signUp: async (
    body: TSignUp
  ): Promise<AxiosResponse<TResponse<TSignUpRes>>> =>
    api.post("/register", body),

  register: async (
    body: TGoogleVerify
  ): Promise<AxiosResponse<TResponse<TUser>>> =>
    api.post("/verify-2FAuth", body),
  googleVerify: async (
    body: TGoogleVerify
  ): Promise<AxiosResponse<TResponse<TUser>>> =>
    api.post("/verify-2FAuth", body),
  forgetPassword: async (
    body: TForgetPassword
  ): Promise<AxiosResponse<TResponse<[]>>> =>
    api.post("/password/forgot", body),

  resetPassword: async (
    body: TResetPassword
  ): Promise<AxiosResponse<TResponse<[]>>> => api.post("/password/reset", body),

  logout: async (): Promise<AxiosResponse<TResponse<[]>>> =>
    api.post("/logout"),
};

export default authService;
