/** @format */

import { useQuery } from "react-query";
import ordersServices from "../services/orders";
import { tags } from "../services/tags";
import { TParams } from "../types/api";

export const useGetAllOrders = (params: TParams) => {
  const query = useQuery(tags.getOrders, async () => {
    const { data } = await ordersServices.getOrders(params);
    return data.data;
  });
  return { ...query };
};
export const useGetAllOrdersIds = () => {
  const query = useQuery("", async () => {
    const { data } = await ordersServices.getOrdersIds();
    return data.data;
  });
  return { ...query };
};

export const useGetOrder = (id: string) => {
  const query = useQuery(
    tags.getOrder,
    async () => {
      const { data } = await ordersServices.getOrder(id);
      return data.data;
    },
    { enabled: !!id }
  );
  return { ...query };
};
