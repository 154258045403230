import { TableProps } from "antd";
import { useMemo } from "react";
import { useDeleteAttachment } from "../../../hooks/profile";
import { TAttachment } from "../../../types/attachment";
import formateDate from "../../../utils/formateDate.";
import CustomTable from "../../Common/CustomTable/CustomTable";
import FilePreviewer from "../../Common/TableComponents/FilePreviewer/FilePreviewer";
import Settings from "../../Common/TableComponents/Settings/Settings";

type TProps = {
  data?: TAttachment[];
  isLoading: boolean;
};
function DocumentsTable({ data, isLoading }: TProps) {
  const { mutate: deleteFile, isLoading: deleteFileLoader } =
    useDeleteAttachment();
  const columns: TableProps<TAttachment>["columns"] = useMemo(
    () => [
      {
        title: "اسم الملف",
        dataIndex: "extension",
        key: "extension",
        render: (val, record) => (
          <FilePreviewer type={record?.type as any} fileType={val as string} />
        ),
      },
      {
        title: "النوع",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "الحجم",
        dataIndex: "size",
        key: "size",
        render: (val) => <p>{`${val} KB`}</p>,
      },
      {
        title: "تاريخ الرفع",
        dataIndex: "created_at",
        key: "created_at ",
        render: (val) => <p>{formateDate(val)}</p>,
      },
      {
        title: "الإجراء",
        dataIndex: "id",
        key: "id",
        render: (val, record) => (
          <Settings
            onPreview={() => window.open(record.file_url)}
            onDownload={() => window.open(record.file_url)}
            onDelete={() => deleteFile(val)}
          />
        ),
      },
    ],
    []
  );
  return (
    <CustomTable<TAttachment>
      heading="الوثائق"
      columns={columns as any}
      dataSource={data}
      loading={isLoading || deleteFileLoader}
    />
  );
}

export default DocumentsTable;
