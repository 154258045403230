/** @format */
import clsx from "clsx";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLoginUser } from "../../../hooks/profile";
import useMediaQuery from "../../../hooks/useMediaQuery";
import "../../../styles/main.scss";
import changeLanguage from "../../../utils/changeLanguage";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import SideNav from "../SideNav/SideNav";
import styles from "./Layout.module.scss";

type TProps = {
  children: React.ReactNode;
};

function Layout({ children }: TProps) {
  const { isApproved, user } = useLoginUser();
  const {
    i18n: { language },
  } = useTranslation();
  const isMobile = useMediaQuery("( max-width: 768px )");
  const [isSideNavExpanded, setSideNavExpanded] = useState(
    localStorage.getItem("sideNavExpanded") === "true"
  );

  const location = useLocation();

  useLayoutEffect(() => {
    if (isMobile) {
      setSideNavExpanded(false);
    }
  }, [isMobile]);

  useEffect(() => {
    changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (isMobile) {
      setSideNavExpanded(false);
    }
  }, [location.pathname]);

  return (
    <div>
      {location.pathname.includes("auth") ? (
        <div className={styles.pageWrapper}>{children}</div>
      ) : (
        <div
          className={clsx(
            styles.layout,
            !isSideNavExpanded && styles.collapsed,
            isMobile && styles.floated,
            !isApproved && styles.userNotApproved
          )}
        >
          {isMobile && isSideNavExpanded ? (
            <div
              onClick={() => setSideNavExpanded(false)}
              className={styles.overlay}
            />
          ) : null}

          <div
            className={clsx(
              styles.sideNav,
              isSideNavExpanded && isMobile && styles.openSideNav
            )}
          >
            <SideNav isExpanded={isSideNavExpanded} user={user} />
          </div>
          <div className={styles.content}>
            <div className={styles.navbar}>
              <Navbar
                user={user}
                setSideNavExpanded={setSideNavExpanded}
                isExpanded={isSideNavExpanded}
              />
            </div>

            <div className={styles.pageWrapper}>{children}</div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Layout;
