/** @format */

import { AxiosResponse } from "axios";
import { TPaginationResponse, TParams, TResponse } from "../types/api";
import { TOrder, TOrderDetails } from "../types/order";
import api from "./api";

const ordersServices = {
  getOrders: async (
    params: TParams
  ): Promise<AxiosResponse<TPaginationResponse<TOrder[]>>> =>
    api.get("/store/orders", { params }),

  getOrdersIds: async (): Promise<AxiosResponse<TResponse<string[]>>> =>
    api.get("/order-ids"),

  getOrder: async (
    id: string
  ): Promise<AxiosResponse<TResponse<TOrderDetails>>> =>
    api.get("/store/orders/" + id),
};

export default ordersServices;
