/** @format */

import clsx from "clsx";
import { useState } from "react";
import { BiSolidDashboard, BiSolidStore, BiSupport } from "react-icons/bi";
import { FaChartPie } from "react-icons/fa";
import { FaBoxArchive } from "react-icons/fa6";
import { LuMinus } from "react-icons/lu";
import { MdFavoriteBorder, MdShoppingCartCheckout } from "react-icons/md";
import { PiWalletBold } from "react-icons/pi";
import { RxChevronDown, RxChevronLeft } from "react-icons/rx";
import { VscGear } from "react-icons/vsc";
import logoBlack from "../../../assets/images/logo-onBlack.png";
import logoResponsive from "../../../assets/images/mobile-logo.png";
import { useGetProfile } from "../../../hooks/profile";
import { Permissions } from "../../../services/permissions";
import { TUser } from "../../../types/auth";
import CustomLink from "../CustomLink/CustomLink";
import styles from "./SideNav.module.scss";
import { TMenuLink } from "./types";

type TProps = {
  isExpanded: boolean;
  user?: TUser;
};

function SideNav({ isExpanded, user }: TProps) {
  const { data } = useGetProfile();
  const [activeLink, setActiveLink] = useState("");
  const [activeSubLink, setActiveSubLink] = useState("");
  const userPermissions = user?.permissions?.map((item) => item.name);

  const links: TMenuLink[] = [
    {
      id: Permissions.HomeSection,
      label: "لوحة التحكم",
      href: `/`,
      icon: <BiSolidDashboard />,
    },
    {
      id: "wallet",
      label: " المحفظة",
      icon: <PiWalletBold />,
      note: `${Number(user?.balance).toFixed(2)} ${
        data?.currency?.code || "ر.س"
      }`,
      children: [
        {
          id: Permissions.WalletAddBalance,
          label: " شحن الرصيد ",
          href: `/charge-wallet`,
        },
        {
          id: Permissions.WalletBalanceList,
          label: "عمليات شحن الرصيد",
          href: `/balance-list`,
        },
      ],
    },
    {
      id: Permissions.ProductsList,
      label: "قائمة المنتجات",
      href: `/products`,
      icon: <FaBoxArchive />,
    },
    {
      id: Permissions.Store,
      label: "المتجر",
      href: `/store`,
      icon: <BiSolidStore />,
    },
    {
      id: Permissions.FavoritesProductsList,
      label: "المنتجات المفضلة",
      href: `/fav`,
      icon: <MdFavoriteBorder />,
    },
    {
      id: Permissions.OrdersList,
      label: "طلباتي",
      href: `/orders`,
      icon: <MdShoppingCartCheckout />,
    },
    {
      id: Permissions.Reports,
      label: "التقارير",
      href: `/reports`,
      icon: <FaChartPie />,
    },
    {
      id: "settings",
      label: "الإعدادات",
      icon: <VscGear />,
      children: [
        {
          id: Permissions.SettingViewProfile,
          label: "الملف الشخصي",
          href: `/settings/profile/view`,
        },
        {
          id: Permissions.SettingAdminList,
          label: "موظفو النظام",
          href: `/settings/employees`,
        },
      ],
    },
    {
      id: Permissions.ViewTicket,
      label: "الدعم",
      href: `/support`,
      icon: <BiSupport />,
    },
  ];

  const renderLinks = () => {
    const allPermissions: string[] = [];

    if (userPermissions) {
      allPermissions.push(...userPermissions);
    }

    const authorizedLinks = links.filter((link) => {
      if (link.children) {
        const newLinks = link.children.filter(
          (subLink) => subLink && allPermissions.includes(subLink.id)
        );
        link.children = newLinks;
        return newLinks.length;
      } else {
        return allPermissions.includes(link.id);
      }
    });

    return authorizedLinks;
  };

  return (
    <aside
      className={clsx(styles.sideNav, !isExpanded && styles.sideNavCollapsed)}>
      <nav className={styles.content}>
        <div className={styles.logo}>
          <img alt='logo' src={isExpanded ? logoBlack : logoResponsive} />
        </div>
        <ul className={styles.menu}>
          {renderLinks()?.map((link) => (
            <MenuItem
              key={link?.id}
              link={link}
              isExpanded={isExpanded}
              setActiveLink={setActiveLink}
              activeLink={activeLink}
              activeSubLink={activeSubLink}
              setActiveSubLink={setActiveSubLink}
            />
          ))}
        </ul>
      </nav>
    </aside>
  );
}
export default SideNav;

const RenderChevron = ({
  id,
  activeLink,
}: {
  id: string;
  activeLink: string;
}) => {
  if (activeLink === id) {
    return <RxChevronDown />;
  }
  return <RxChevronLeft />;
};

const MenuItem = ({
  link,
  isExpanded,
  setActiveLink,
  activeLink,
  setActiveSubLink,
  activeSubLink,
}: {
  link: TMenuLink;
  isExpanded: boolean;
  setActiveLink: React.Dispatch<React.SetStateAction<string>>;
  activeLink: string;
  setActiveSubLink: React.Dispatch<React.SetStateAction<string>>;
  activeSubLink: string;
}) => {
  const LinkItem = () => {
    return (
      <div className={styles.link}>
        <div className={styles.icon}>{link.icon}</div>
        <div className={clsx(styles.link, styles.linkLabel)}>
          <div className={clsx(styles.flex)}>
            <span className={styles.mainLink}>{link.label}</span>
            {link.note && <span className={styles.note}>{link.note}</span>}
          </div>
          {!isExpanded &&
            link.children?.map((subLink) => (
              <div className={clsx(styles.menuItem)} key={subLink?.id}>
                <CustomLink
                  to={subLink?.href || ""}
                  className={clsx(styles.link, styles.subLinkCollapsed)}>
                  {subLink?.label}
                </CustomLink>
              </div>
            ))}
        </div>
      </div>
    );
  };
  return (
    <>
      <li
        key={link.id}
        className={clsx(
          styles.menuItem,
          activeLink === link.id && styles.menuItemActiveLink
        )}
        onClick={() => {
          setActiveLink((prev) => {
            if (link.children?.length) {
              return prev === link.id ? "" : link.id;
            } else {
              return link.id;
            }
          });
        }}>
        {link.href ? (
          <CustomLink to={link.href}>
            <LinkItem />
          </CustomLink>
        ) : (
          <LinkItem />
        )}

        {link.children?.length && (
          <div className={styles.showMoreLinksChevron}>
            <RenderChevron activeLink={activeLink} id={link.id} />
          </div>
        )}
      </li>
      {activeLink === link.id &&
        isExpanded &&
        link.children?.map((subLink) => (
          <li
            className={clsx(
              styles.menuItem,
              activeSubLink === subLink?.id && styles.menuItemActiveLink
            )}
            key={subLink?.id}
            onClick={() => setActiveSubLink(subLink?.id as string)}>
            <CustomLink
              to={subLink?.href || ""}
              className={clsx(styles.subLink, styles.link)}>
              <div className={styles.icon}>
                <LuMinus />
              </div>
              {subLink?.label}
            </CustomLink>
          </li>
        ))}
    </>
  );
};
