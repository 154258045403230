import { GoPencil } from "react-icons/go";
import useAuthorization from "../../../hooks/useAuthorization";
import { TUser } from "../../../types/auth";
import formateDate from "../../../utils/formateDate.";
import CustomLink from "../../Common/CustomLink/CustomLink";
import styles from "./SellerDetails.module.scss";

const sellerDetails = (data?: TUser) => [
  {
    label: "اسم الشركة :",
    value: data?.name,
  },
  {
    label: "  المدير المسئول :",
    value: data?.owner_name,
  },
  {
    label: " رقم الهاتف :",
    value: data?.phone,
  },
  {
    label: " البريد الإلكتروني :",
    value: data?.email,
  },
  {
    label: " رقم السجل التجاري :",
    value: data?.commercial_register_number,
  },
  {
    label: " رقم البطاقة الضريبية :",
    value: data?.tax_card_number,
  },
  {
    label: " الدولة :",
    value: data?.country?.name,
  },
  {
    label: "المدينة :",
    value: data?.city?.name,
  },
  {
    label: " العنوان :",
    value: data?.address,
  },
  {
    label: " تاريخ الإنضمام  :",
    value: formateDate(data?.created_at, "DD MMMM YYYY"),
  },
];

type TProps = {
  data?: TUser;
};
function SellerDetails({ data }: TProps) {
  const { hasPermission } = useAuthorization();

  return (
    <div className={styles.sellerDetails}>
      <div className={styles.header}>
        <h5 className={styles.title}>بيانات التاجر</h5>
        {hasPermission("SettingUpdateProfile") && (
          <CustomLink to={"/settings/profile/edit"}>
            <GoPencil className={styles.pencil} />
          </CustomLink>
        )}
      </div>
      <table className={styles.content}>
        <tbody>
          {sellerDetails(data).map((item) => (
            <tr key={item.label} className={styles.tableRow}>
              <th>{item.label}</th>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SellerDetails;
