/** @format */

import Box from "../../../components/Common/Box";
import AddTicketForm from "../../../components/Support/AddTicket";

export default function AddTicket() {
  return (
    <Box title='إضافة تذكرة'>
      <AddTicketForm />
    </Box>
  );
}
