/** @format */

import { Spin } from "antd";
import style from "./style.module.scss";

export default function Spinner() {
  return (
    <div className={style.spinnerCont}>
      <Spin />{" "}
    </div>
  );
}
