/** @format */

import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import GoogleAuthVerify from "../../../components/auth/Forms/GoogleAuthVerify/GoogleAuthVerify";

function Page() {
  document.title = "تسجيل موثقي جوجل";
  return (
    <AuthLayout lottie={SignUpLottie}>
      <GoogleAuthVerify />
    </AuthLayout>
  );
}
export default Page;
