/** @format */

import { useState } from "react";
import StorePage from "../../components/Store";
import FavComp from "../../components/Store/Fav";
import { useFav } from "../../hooks/store";

export default function Fav() {
  const [pageSize, setPageSize] = useState("10");
  const [filters, setFilters] = useState({ page: " 1", search: "" });
  const { data } = useFav({ ...filters, pageSize: pageSize });
  const saveFilters = (stateName: string, value: string) => {
    setFilters({ ...filters, [stateName]: value });
  };
  return (
    <StorePage title='البطاقات المفضلة'>
      <FavComp
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data}
        filters={filters}
        saveFilters={saveFilters}
      />
      {/* {data ? (
        <div className={clsx(style.paginationCont, "paginationCont")}>
          <PaginationCom
            setPage={setPage}
            currentPage={currentPage}
            total={data.length}
          />
          <p>
            عرض {(currentPage - 1) * 8 + 1} إلى{" "}
            {currentPage * 8 > data.length ? data.length : currentPage * 8} من{" "}
            {data?.length} بطاقة
          </p>
        </div>
      ) : null} */}
    </StorePage>
  );
}
