import { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import { FaEye } from "react-icons/fa6";
import { PiTrashFill } from "react-icons/pi";
import DeleteModal from "../../DeleteModal/DeleteModal";
import styles from "./RowActions.module.scss";

type TProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  onPreview?: () => void;
  isLoading?: boolean;
};

function RowActions({ onDelete, onEdit, onPreview, isLoading }: TProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteAndCloseModal = () => {
    onDelete && onDelete();
    setTimeout(() => {
      handleCloseDeleteModal();
    }, 3000);
  };
  return (
    <div className={styles.actions}>
      {onDelete && (
        <DeleteModal
          open={showDeleteModal}
          onCancel={handleCloseDeleteModal}
          onOk={handleDeleteAndCloseModal}
          onClose={handleCloseDeleteModal}
          loading={isLoading}
        />
      )}

      {onEdit && <BiSolidPencil className={styles.edit} onClick={onEdit} />}
      {onDelete && (
        <PiTrashFill
          className={styles.trash}
          onClick={() => setShowDeleteModal(true)}
        />
      )}
      {onPreview && <FaEye className={styles.preview} onClick={onPreview} />}
    </div>
  );
}

export default RowActions;
