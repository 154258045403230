import dayjs from "dayjs";
import "dayjs/locale/ar";
import i18n from "../i18n";

function formateDate(date?: string, format = "DD MMMM YYYY hh:mm A") {
  dayjs.locale(i18n.language);
  return dayjs(date).format(format);
}

export default formateDate;
