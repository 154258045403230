/** @format */

import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import currenciesServices from "../services/currencies";
import lookupsServices from "../services/lookups";
import { tags } from "../services/tags";
import { TPermission } from "../types/TLookups";
import useValidateAllTags from "./useValidateAllTags";

export const useGetAllCountries = () => {
  const query = useQuery(tags.getCountries, async () => {
    const { data } = await lookupsServices.getAllCountries();
    return data.data;
  });
  return { ...query };
};
export const useGetAllRegions = () => {
  const query = useQuery(tags.getRegions, async () => {
    const { data } = await lookupsServices.getAllRegions();
    return data.data;
  });
  return { ...query };
};

export const useGetAllCities = () => {
  const query = useQuery(tags.getCities, async () => {
    const { data } = await lookupsServices.getAllCities();
    console.log(data.data);
    return data.data;
  });
  return { ...query };
};

export const useGetAllPermissions = () => {
  const query = useQuery(tags.getPermissions, async () => {
    const { data } = await lookupsServices.getAllPermissions();
    createPermissionsConfig(data.data);
    return data.data;
  });
  return { ...query };
};

export const useGetAllSettings = () => {
  const query = useQuery(tags.getAllSettings, async () => {
    const { data } = await lookupsServices.getAllSettings();
    return data.data;
  });
  return { ...query };
};

const createPermissionsConfig = (data?: TPermission[]) => {
  const config: any = {};
  data?.forEach((item) => {
    const keyName = item.name
      .split("-")
      .map((t) => t[0].toUpperCase() + t.slice(1))
      .join("");
    config[keyName] = item.name;
  });
  console.log({ permissions: config });
  return config;
};

export const useGetAllCurrencies = () => {
  const query = useQuery(tags.getCurrencies, async () => {
    const { data } = await currenciesServices.getAllCurrencies();
    return data.data;
  });
  return { ...query };
};
export const useGetAllNotification = () => {
  const query = useQuery(tags.getNotification, async () => {
    const { data } = await lookupsServices.getAllNotification();
    return data.data;
  });
  return { ...query };
};
export const useReadAllNotification = () => {
  const query = useQuery(tags.readNotification, async () => {
    const { data } = await lookupsServices.readAllNotification();
    return data.data;
  });
  return { ...query };
};

export const useGetAllNotificationCount = () => {
  const query = useQuery(tags.getNotificationCount, async () => {
    const { data } = await lookupsServices.getAllNotificationCount();
    return data.data;
  });
  return { ...query };
};

export const useChangeCurrency = () => {
  const { validateAllTags } = useValidateAllTags();
  const mutation = useMutation(currenciesServices.changeCurrency, {
    onSuccess: (data) => {
      validateAllTags();
      toast.success(data.data.message);
    },
  });
  return { ...mutation };
};
export const useSaveToken = () => {
  const mutation = useMutation(lookupsServices.saveToken);
  return { ...mutation };
};
