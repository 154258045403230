/** @format */

"use client";
import { Form, Input } from "antd";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPassword } from "../../../../hooks/auth";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import styles from "../Forms.module.scss";

function ResetPasswordForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useResetPassword(navigate);
  const [searchParams] = useSearchParams();
  console.log(searchParams);

  useEffect(() => {
    if (!searchParams.has("email")) {
      navigate("/auth/forget-password");
    }
  }, [searchParams]);

  return (
    <Form
      onFinish={(values) =>
        mutate({ ...values, email: searchParams.get("email") })
      }
      rootClassName={styles.form}
    >
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="رمز التحقق"
        name="otp"
        rules={[{ required: true, message: "  رمز  التحقق مطلوب" }]}
      >
        <Input type="number" placeholder=" ادخل رمز التحقق" />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="كلمة المرور"
        name="password"
        rules={[{ required: true, message: " كلمة المرور مطلوبة" }]}
      >
        <Input.Password placeholder=" ادخل كلمة المرور" />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="تأكيد كلمة المرور"
        name="password_confirmation"
        rules={[
          { required: true, message: " تأكيد كلمة المرور  مطلوبة" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error("كلمة المرور غير متطابقة"));
            },
          }),
        ]}
      >
        <Input.Password placeholder=" ادخل كلمة المرور" />
      </Form.Item>

      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        حفظ{" "}
      </CustomBtn>
    </Form>
  );
}
export default ResetPasswordForm;
