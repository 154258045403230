/** @format */

import { useEffect, useState } from "react";
import { useGetEmployees } from "../../../hooks/employee";
import useAuthorization from "../../../hooks/useAuthorization";
import SectionActions from "../../Common/SectionActions/SectionActions";
import CustomSkelton from "../../CustomSkelton/CustomSkelaton";
import ListEmployeeTable from "../ListEmployeeTable/ListEmployeeTable";
import styles from "./ListEmployees.module.scss";

function ListEmployees() {
  const [params, setParams] = useState({ name: "" });
  const { data, isLoading, refetch } = useGetEmployees(params);
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    refetch();
  }, [params]);

  if (isLoading) {
    return <CustomSkelton />;
  }

  document.title = "قائمة الموظفين";
  return (
    <div className={styles.listEmployees}>
      <SectionActions
        search={{
          onChange: (name) => setParams((prev) => ({ ...prev, name })),
          placeholder: "البحث عن موظف  ......",
        }}
        button={
          hasPermission("SettingAddAdminList")
            ? {
                href: "/settings/Employees/add",
                value: "اضف موظف جديد",
              }
            : undefined
        }
      />
      <ListEmployeeTable data={data} isLoading={isLoading} />
    </div>
  );
}

export default ListEmployees;
