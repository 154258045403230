import { Checkbox, Spin } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useGetAllPermissions } from "../../../hooks/lookups";
import { PermissionsCategoriesList } from "../../../services/permissions";
import styles from "./PermissionsCategories.module.scss";

type TProps = {
  defaultPermissions?: string[];
  getPermissions: Dispatch<SetStateAction<string[]>>;
};

function PermissionsCategories({ defaultPermissions, getPermissions }: TProps) {
  const { data, isLoading } = useGetAllPermissions();
  const [permissions, setPermissions] = useState<string[]>([]);
  useEffect(() => {
    if (defaultPermissions?.length) setPermissions(defaultPermissions);
  }, [defaultPermissions?.length]);

  useEffect(() => {
    getPermissions(permissions);
  }, [permissions.length]);

  const findPermissionItems = (val: string) => {
    return data?.find((item) => item.name === val);
  };
  const toggleCategory = (items: string[]) => {
    const isExist = items.every((item) => permissions?.includes(item));
    if (isExist) {
      setPermissions(permissions.filter((item) => !items.includes(item)));
    } else {
      setPermissions([...permissions, ...items]);
    }
    return isExist;
  };
  return (
    <Spin spinning={isLoading}>
      <div className={styles.permissionsCategories}>
        <Checkbox
          onChange={({ target: { checked } }) => {
            if (checked) {
              setPermissions(data?.map((item) => item.name) || []);
            } else {
              setPermissions([]);
            }
          }}
          checked={permissions?.length === data?.length}
        >
          تحديد الكل
        </Checkbox>
        <div className={styles.categoriesContainer}>
          {PermissionsCategoriesList.map((item) => (
            <ul key={item.name} className={styles.categories}>
              <li onClick={() => toggleCategory(item.items)}>
                <Checkbox
                  checked={item.items.every((item) =>
                    permissions?.includes(item)
                  )}
                >
                  <b>{item.name}</b>
                </Checkbox>
              </li>
              {item.items.map((subItem) => (
                <li key={subItem}>
                  <Checkbox
                    onChange={({ target: { checked } }) => {
                      if (checked) {
                        setPermissions((prev) => [...prev, subItem]);
                      } else {
                        setPermissions((prev) =>
                          prev.filter((item) => item !== subItem)
                        );
                      }
                    }}
                    checked={permissions?.includes(subItem)}
                  >
                    {findPermissionItems(subItem)?.display_name}
                  </Checkbox>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </Spin>
  );
}

export default PermissionsCategories;
