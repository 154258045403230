import { UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { RiUploadCloud2Fill } from "react-icons/ri";
import styles from "./Upload.module.scss";

type TProps = UploadProps;

function Upload({
  accept = "image/png; image/jpeg; file/pdf; file/doc",
  ...props
}: TProps) {
  return (
    <Dragger
      onChange={props.onChange}
      accept={accept}
      {...props}
      className={styles.upload}
    >
      <p className={styles.uploadIcon}>
        <RiUploadCloud2Fill />
      </p>
      <p className={styles.uploadText}>قم بسحب الملفات هنا أو اضغط للتحميل</p>
    </Dragger>
  );
}

export default Upload;
