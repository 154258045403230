/** @format */

import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TAddTicketRes, TTicket } from "../types/attachment";
import api from "./api";

export const ticketServices = {
  getTickets: async (): Promise<AxiosResponse<TResponse<TTicket[]>>> =>
    api.get("/support-tickets"),
  addTicket: async (
    payload: FormData
  ): Promise<AxiosResponse<TResponse<TAddTicketRes>>> =>
    api.post("/support-tickets/store", payload),
};
