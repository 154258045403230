import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import rolesService from "../services/roles";
import { tags } from "../services/tags";

export const useAddRole = (navigate?: NavigateFunction) => {
  const queryClient = useQueryClient();
  const mutate = useMutation(rolesService.addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getRoles);
      navigate && navigate(-1);
    },
  });

  return { ...mutate };
};

export const useUpdateRole = (navigate?: NavigateFunction) => {
  const queryClient = useQueryClient();
  const mutate = useMutation(rolesService.updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getRoles);
      queryClient.invalidateQueries(tags.getRole);

      navigate && navigate(-1);
    },
  });

  return { ...mutate };
};

export const useGetRoles = (search?: string) => {
  const query = useQuery(tags.getRoles, async () => {
    const { data } = await rolesService.getAllRoles(search);
    return data.data;
  });
  return { ...query };
};

export const useChangeRoleStatus = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation(rolesService.changeRoleStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getRoles);
    },
  });

  return { ...mutate };
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation(rolesService.deleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getRoles);
    },
  });

  return { ...mutate };
};

export const useGetRoleDetails = (id: number) => {
  const query = useQuery(
    tags.getRole,
    async () => {
      const { data } = await rolesService.getRoleDetails(id);
      return data.data;
    },
    { enabled: !!id }
  );
  return { ...query };
};
