import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthorization from "../../../hooks/useAuthorization";
import { Permissions } from "../../../services/permissions";

type TProps = {
  children: React.ReactNode;
  permissions: (keyof typeof Permissions)[];
};
function Protected({ permissions, children }: TProps) {
  const { hasPermission, isSuccess, isFetching, isError } = useAuthorization();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      permissions.forEach((permission) => {
        if (!hasPermission(permission as keyof typeof Permissions)) {
          navigate("/unauthorized");
        }
      });
    }
    if (isError) {
      navigate("/unauthorized");
    }
  }, [isSuccess, isFetching, isError]);

  return <>{children}</>;
}

export default Protected;
