/** @format */

import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { tags } from "../services/tags";
import { ticketServices } from "../services/tickets";
const getTickets = async () => {
  const {
    data: { data },
  } = await ticketServices.getTickets();
  return data;
};
export const useGitTicket = () => {
  const mutation = useQuery(tags.getTickets, () => getTickets());
  return { ...mutation };
};

export const useAddTicket = (navigate: NavigateFunction) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(ticketServices.addTicket, {
    onSuccess: ({ data: res }) => {
      const { status, message } = res;
      if (status) {
        toast.success(message);
        queryClient.invalidateQueries(tags.getTickets);
        navigate(`/support`);
      }
    },
  });
  return { ...mutation };
};
