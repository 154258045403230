function handleFormData(values: any) {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (!values[key]) return;

    if (Array.isArray(values[key])) {
      values[key].forEach((item: any, index: number) =>
        formData.append(`${key}[${index}]`, item)
      );
      return;
    }

    formData.append(key, values[key]);
  });

  return formData;
}
export default handleFormData;
