import { TableProps, Tag } from "antd";
import { useMemo } from "react";
import { productStatus } from "../../../constant/ProductType";
import formateDate from "../../../utils/formateDate.";
import CustomLink from "../../Common/CustomLink/CustomLink";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./OrdersDetails.module.scss";

type TProps = {
  data?: any;
  isLoading?: boolean;
  handlePagination: (page: number) => void;
};
function OrdersDetails({ data, isLoading, handlePagination }: TProps) {
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: " تاريخ الإضافة		",
        dataIndex: "created_at",
        key: "created_at",
        render: (_, record) => (
          <p>{formateDate(record.created_at, "YYYY-MM-DD")}</p>
        ),
      },
      {
        title: "  اسم المنتج		",
        dataIndex: "productName",
        minWidth: 300,
        key: "productName",
      },
      {
        title: " النوع",
        dataIndex: "type",
        key: "type",
        render: (val) => (
          <Tag
            className={styles.tag}
            color={productStatus[val as keyof typeof productStatus]?.color}
          >
            {productStatus[val as keyof typeof productStatus]?.label}{" "}
          </Tag>
        ),
      },
      {
        title: " رقم الطلب	",
        dataIndex: "orderNumber",
        key: "orderNumber",
        render: (val) => (
          <CustomLink className={styles.link} to={`/orders/${val}`}>
            {val}
          </CustomLink>
        ),
      },
      {
        title: " القيمة 	",
        dataIndex: "unit_price",
        key: "unit_price",
        render: (val) => (
          <p>
            <b>{val.toFixed(2)}</b>
          </p>
        ),
      },
      {
        title: " الإنشاء بواسطة",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (val) => <b>{val}</b>,
      },
    ],
    []
  );
  return (
    <div className={styles.brandSalesTable}>
      <CustomTable
        columns={columns}
        className={styles.customTable}
        dataSource={data?.data}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
        loading={isLoading}
        pagination={{
          showSizeChanger: false,
          current: data?.current_page,
          pageSize: data?.per_page,
          total: data?.total,
          onChange: handlePagination,
        }}
      />
    </div>
  );
}

export default OrdersDetails;
