/** @format */

import styles from "./Footer.module.scss";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <p>{year} © Take Card</p>
      {/* <p>
        Design &amp; Develop by{" "}
        <Link
          className={styles.link}
          to="https://www.multi-choice.com/"
          target="_blank"
        >
          Multi-Choice
        </Link>
      </p> */}
    </footer>
  );
}
export default Footer;
