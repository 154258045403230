import { Player } from "@lordicon/react";
import { DatePicker, Input, Select } from "antd";
import { useEffect, useRef } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { LuSearch } from "react-icons/lu";
import { RiFileExcel2Line } from "react-icons/ri";
import CardsNumber from "../../../assets/lottie/cards-num.json";
import Coin from "../../../assets/lottie/coin.json";
import Tax from "../../../assets/lottie/tax.json";
import TotalWithTax from "../../../assets/lottie/total-with-tax.json";
import styles from "./ReportsActions.module.scss";

const { RangePicker } = DatePicker;

type TProps = {
  activeTab: string;
  onSearch: (value: string) => void;
  onSelectDate: (startData: string, endData: string) => void;
  onSelectType: (type: string) => void;
  onSelectBrand: (brandId: number) => void;
  stats: Partial<{
    tax: number;
    total: number;
    totalAfterTax: number;
    cardsNumber: number;
  }>;
};
function ReportsActions({
  activeTab,
  onSearch,
  onSelectType,
  onSelectDate,
  onSelectBrand,
  stats,
}: TProps) {
  const playerRef1 = useRef<Player>(null);
  const playerRef2 = useRef<Player>(null);
  const playerRef3 = useRef<Player>(null);

  useEffect(() => {
    playerRef1.current?.playFromBeginning();
    playerRef2.current?.playFromBeginning();
    playerRef3.current?.playFromBeginning();
  }, [activeTab]);

  return (
    <div className={styles.reportsActions}>
      <div className={styles.filters}>
        <div className={styles.inputs}>
          <RangePicker
            lang={"ar"}
            placeholder={["من", "إلى"]}
            onChange={(dates: any) =>
              DataTransfer?.length && onSelectDate(dates[0], dates[1])
            }
          />
          <Input
            onChange={(e) => onSearch(e.target.value)}
            className={styles.searchInput}
            prefix={<LuSearch />}
            placeholder="بحث"
          />
          {activeTab === "ordersDetails" && (
            <Select
              onChange={onSelectType}
              placeholder="اختر نوع الطلب"
              options={[
                { value: "topup", label: "توب اب" },
                { value: "serial", label: "سيريال" },
              ]}
            />
          )}
          {activeTab === "products" && (
            <Select
              onChange={onSelectType}
              placeholder="اختر العلامة التجارية"
              options={[]}
            />
          )}
        </div>
        <div className={styles.export}>
          <b>تصدير :</b>
          <div className={styles.exportType}>
            <span>PDF</span>
            <FaRegFilePdf />
          </div>
          <div className={styles.exportType}>
            <span>Excel</span>
            <RiFileExcel2Line />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      {activeTab === "products" && (
        <ul className={styles.stats}>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={Coin}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.total}</b>&nbsp;
            <span>ر.س</span>
            <p> إجمالي السعر</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef2.current?.playFromBeginning()}
              size={55}
              ref={playerRef2}
              icon={Tax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.tax}</b>
            <p> إجمالي الضريبة</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef3.current?.playFromBeginning()}
              size={55}
              ref={playerRef3}
              icon={TotalWithTax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.totalAfterTax} </b>&nbsp;
            <span>ر.س</span>
            <p>الإجمالي بعد الضريبة</p>
          </li>
        </ul>
      )}
      {activeTab === "orders" && (
        <ul className={styles.stats}>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={CardsNumber}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.cardsNumber}</b>&nbsp;
            <p>عدد البطاقات</p>
          </li>
          <li>
            <Player
              onComplete={() => playerRef1.current?.playFromBeginning()}
              size={55}
              ref={playerRef1}
              icon={TotalWithTax}
              colors="primary:#6691e7,secondary:#fb8b02"
            />
            <b className={styles.total}>{stats.total} </b>&nbsp;
            <p> إجمالي القيمة</p>
          </li>
        </ul>
      )}
    </div>
  );
}

export default ReportsActions;
