/** @format */

import { StyleProvider } from "@ant-design/cssinjs";
import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ProtectedRoutes from "./components/Common/ProtectedRoutes/ProtectedRoutes";
import { NotificationManger } from "./context/NotificationManger";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false, // Disable retries on failure
      staleTime: Infinity, // Prevent automatic invalidation
      cacheTime: Infinity, // Keep data indefinitely in cache
    },
  },
});

type TProps = {
  children: ReactNode;
};
function App({ children }: TProps) {
  return (
    <ConfigProvider
      theme={{
        cssVar: false,
        hashed: false,
        token: {
          colorPrimary: "#6691e7",
          fontFamily: "Tajawal, system-ui",
        },
      }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <StyleProvider hashPriority='high'>
          <NotificationManger>
            <ProtectedRoutes>{children}</ProtectedRoutes>
          </NotificationManger>
        </StyleProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
