import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import employeesServices from "../services/employees";
import { tags } from "../services/tags";

export const useGetEmployees = (params?: { name: string }) => {
  const query = useQuery(tags.getEmployees, async () => {
    const { data } = await employeesServices.getAllEmployees(params);
    return data.data;
  });
  return { ...query };
};

export const useUpdateEmployeeStatus = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(employeesServices.updateEmployeeStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getEmployees);
    },
  });

  return { ...mutation };
};

export const useCreateEmployee = (navigate: NavigateFunction) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(employeesServices.createEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getEmployees);
      navigate(-1);
    },
  });

  return { ...mutation };
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(employeesServices.deleteEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getEmployees);
    },
  });

  return { ...mutation };
};

export const useGetEmployeeDetails = (id: number) => {
  const query = useQuery(
    tags.getEmployee,
    async () => {
      const { data } = await employeesServices.getEmployeeDetails(id);
      return data.data;
    },
    { enabled: !!id }
  );
  return { ...query };
};

export const useUpdateEmployee = (navigate: NavigateFunction) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(employeesServices.updateEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(tags.getEmployees);
      queryClient.invalidateQueries(tags.getEmployee);

      navigate(-1);
    },
  });

  return { ...mutation };
};
