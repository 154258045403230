export const productStatus = {
  serial: {
    color: "blue",
    label: "سيريال",
  },
  topup: {
    color: "yellow",
    label: "توب اب",
  },
};
