/** @format */

"use client";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useForgetPassword } from "../../../../hooks/auth";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import styles from "../Forms.module.scss";

function ForgetPasswordForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const { mutate, isLoading } = useForgetPassword(navigate);
  return (
    <Form onFinish={(values) => mutate(values)} rootClassName={styles.form}>
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <p className={styles.forgetPasswordNote}>
        أدخل بريدك الإلكتروني وسيتم إرسال التعليمات إليك!
      </p>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="البريد الإلكتروني"
        name="email"
        rules={[{ required: true, message: "البريد الإلكتروني مطلوب" }]}
      >
        <Input type="email" placeholder="ادخل بريدك الإلكتروني" />
      </Form.Item>

      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        إرسال رابط إعادة الضبط
      </CustomBtn>
    </Form>
  );
}
export default ForgetPasswordForm;
