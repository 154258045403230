import { useEffect, useState } from "react";
import {
  useGetOrderProductsReport,
  useGetOrdersReport,
  useGetReportProducts,
} from "../../hooks/reports";
import { TReportParams } from "../../types/api";
import styles from "./index.module.scss";
import Orders from "./Orders/Orders";
import OrdersDetails from "./OrdersDetails/OrdersDetails";
import ProductSalesTable from "./ProductSalesTable/ProductSalesTable";
import ReportsActions from "./ReportsActions/ReportsActions";
import ReportsTab from "./ReportsTab/ReportsTab";

function ReportsContent() {
  const [params, setParams] = useState<TReportParams>({});
  const [activeTab, setActiveTab] = useState("products");

  const {
    data: products,
    isLoading: isProductsLoading,
    refetch: refetchProducts,
  } = useGetReportProducts({ ...params, product_name: params.search });

  const {
    data: orderDetails,
    isLoading: isOrderDetailsLoading,
    refetch: refetchOrderDetails,
  } = useGetOrderProductsReport(params);

  const {
    data: orders,
    isLoading: isOrdersLoading,
    refetch: refetchOrders,
  } = useGetOrdersReport(params);

  useEffect(() => {
    setParams(() => ({}));
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === "products") refetchProducts();
    if (activeTab === "orders") refetchOrders();
    if (activeTab === "ordersDetails") refetchOrderDetails();
  }, [params]);

  const handlePagination = (page: number) => {
    setParams((prev) => ({ ...prev, page }));
  };

  const handleSelectDate = (startData: string, endData: string) => {
    setParams((prev) => ({ ...prev, fromDate: startData, toDate: endData }));
  };
  const handleSearch = (name: string) => {
    setParams((prev) => ({ ...prev, search: name }));
  };

  const handleType = (type: string) => {
    setParams((prev) => ({ ...prev, type }));
  };

  const handleBrandChange = (brand_id: number) => {
    setParams((prev) => ({ ...prev, brand_id }));
  };

  return (
    <div className={styles.reportsContent}>
      <ReportsTab active={activeTab} setActive={setActiveTab} />
      <div className={styles.reportsTable}>
        <ReportsActions
          key={activeTab}
          onSearch={handleSearch}
          onSelectDate={handleSelectDate}
          onSelectType={handleType}
          onSelectBrand={handleBrandChange}
          activeTab={activeTab}
          stats={{
            tax: 0,
            total:
              activeTab === "orders"
                ? Number(orders?.data?.totals?.totalOrders.toFixed(2))
                : 0,
            totalAfterTax: 0,
            cardsNumber:
              activeTab === "orders"
                ? orders?.data?.totals?.totalProductsCount
                : 0,
          }}
        />
        {activeTab === "products" && (
          <ProductSalesTable
            data={products}
            handlePagination={handlePagination}
            isLoading={isProductsLoading}
          />
        )}
        {activeTab === "orders" && (
          <Orders
            data={orders?.data.data}
            handlePagination={handlePagination}
            isLoading={isOrdersLoading}
          />
        )}

        {activeTab === "ordersDetails" && (
          <OrdersDetails
            data={orderDetails}
            handlePagination={handlePagination}
            isLoading={isOrderDetailsLoading}
          />
        )}
      </div>
    </div>
  );
}

export default ReportsContent;
