import { TableProps } from "antd";
import { useMemo } from "react";
import formateDate from "../../../utils/formateDate.";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./Orders.module.scss";

type TProps = {
  data?: any;
  isLoading?: boolean;
  handlePagination: (page: number) => void;
};
function Orders({ data, isLoading, handlePagination }: TProps) {
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: " تاريخ الإضافة		",
        dataIndex: "created_at",
        key: "created_at",
        render: (val) => <p>{formateDate(val)}</p>,
      },
      {
        title: " رقم الطلب		",
        dataIndex: "orderNumber",
        key: "orderNumber",
        render: (val) => <p>{val}</p>,
      },
      {
        title: " الإنشاء بواسطة		",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (val) => <b>{val}</b>,
      },
      {
        title: "عدد البطاقات",
        dataIndex: "productsCount",
        key: "productsCount",
      },
      {
        title: "الإجمالي ",
        dataIndex: "total",
        key: "total",
        render: (val) => <b>{val}</b>,
      },
    ],
    []
  );

  return (
    <div className={styles.orders}>
      <CustomTable
        columns={columns}
        className={styles.customTable}
        loading={isLoading}
        dataSource={data?.data}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
      />
    </div>
  );
}

export default Orders;
