/** @format */

import StorePage from "../../components/Store";
import Brands from "../../components/Store/Brands";

export default function Store() {
  return (
    <StorePage>
      <Brands />
    </StorePage>
  );
}
