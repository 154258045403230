import { FaFileAlt, FaImage } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { RiFileExcelFill, RiFileZipFill } from "react-icons/ri";
import styles from "./FilePreviewer.module.scss";

const filesConfig = (fileType: string) => {
  switch (fileType) {
    case "zip":
      return {
        icon: <RiFileZipFill />,
        background: "#e8effb",
        iconColor: "var(--color-med-blue)",
      };
    case "pdf":
      return {
        icon: <FaFilePdf />,
        background: "#fce7e7",
        iconColor: "var(--color-soft-red)",
      };
    case "excel":
      return {
        icon: <RiFileExcelFill />,
        background: "#dcf6e9",
        iconColor: "var(--color-green)",
      };
    case "png":
    case "jpg":
      return {
        icon: <FaImage />,
        background: "#fce7e7",
        iconColor: "#ed5e5e",
      };

    default:
      return {
        icon: <FaFileAlt />,
        background: "var(--color-old-lace",
        iconColor: "var(--color-yellow-red)",
      };
  }
};

const fileName = {
  tax_card: "البطاقة الضريبية",
  identity: "الهوية",
  commercial_register: "السجل التجاري",
  more: "اخري",
};

type TProps = {
  fileType: string;
  type: keyof typeof fileName;
};
function FilePreviewer({ fileType, type }: TProps) {
  return (
    <div className={styles.filePreviewer}>
      <div
        style={{
          backgroundColor: filesConfig(fileType)?.background,
          color: filesConfig(fileType)?.iconColor,
        }}
        className={styles.fileType}
      >
        {filesConfig(fileType).icon}
      </div>
      <p className={styles.fileName}>
        {fileName[type as keyof typeof fileName]}{" "}
      </p>
    </div>
  );
}

export default FilePreviewer;
