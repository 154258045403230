/** @format */

import ForgetPassword from "../../../assets/lottie/lottie-forget-password.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import ForgetPasswordForm from "../../../components/auth/Forms/ForgetPasswordForm/ForgetPasswordForm";
function Page() {
  document.title = "إعادة تهيئة كلمة المرور";
  return (
    <AuthLayout lottie={ForgetPassword}>
      <ForgetPasswordForm formHeading=" إعادة تهيئة كلمة المرور  " />
    </AuthLayout>
  );
}
export default Page;
