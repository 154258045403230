/** @format */

"use client";
import { onMessage } from "firebase/messaging";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.css";
import {
  useGetAllNotification,
  useGetAllNotificationCount,
  useSaveToken,
} from "../hooks/lookups";
import { useGetProfile } from "../hooks/profile";
import { generateToken, messaging } from "../notifications/firebase";

type TNotification = {
  id: string;
  message: string;
  type: "success" | "error" | "warning" | "info";
};

type TNotificationMangerProps = {
  children: ReactNode;
};

type TContext = {
  addNotification: ({}: Omit<TNotification, "id">) => void;
};
const NotificationMangerContext = createContext<TContext>({} as TContext);

const NotificationManger = ({ children }: TNotificationMangerProps) => {
  const { data } = useGetProfile();
  const { mutate } = useSaveToken();
  const { refetch } = useGetAllNotification();
  const { refetch: refetchNotificationCount } = useGetAllNotificationCount();
  useEffect(() => {
    const saveToken = async () => {
      const firebase_token = await generateToken();
      onMessage(messaging, (payload: any) => {});
      if (firebase_token) {
        mutate({ firebase_token: firebase_token || "" });
      }

      refetchNotificationCount();
      refetch();
    };

    // const messaging = firebase.messaging();
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then((token: any) => {
    //     console.log(">>>>>>token", token);
    //   })
    //   .catch(() => {
    //     console.log(">>>>eror");
    //   });
    saveToken();
  }, [data]);

  const addNotification = ({ message, type }: Omit<TNotification, "id">) => {
    const isExist = toast.isActive(message);
    if (isExist) return;

    toast(message, {
      type: type,
      closeOnClick: true,
      autoClose: 2500,
      draggable: true,
      toastId: message,
    });
  };

  const value = useMemo(
    () => ({
      addNotification,
    }),
    [addNotification]
  );

  return (
    <NotificationMangerContext.Provider value={value}>
      <ToastContainer newestOnTop rtl closeOnClick />
      {children}
    </NotificationMangerContext.Provider>
  );
};

function useNotificationManger() {
  const context = useContext(NotificationMangerContext);
  if (!context) {
    throw new Error("Context was used outside provider");
  }
  return context;
}

export { NotificationManger, useNotificationManger };
