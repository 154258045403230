/** @format */

import { Checkbox, Form, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "../../../../hooks/auth";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import styles from "../Forms.module.scss";

function SignInForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();

  const { mutate, isLoading } = useSignIn(navigate);
  const [rememberMe, setRememberMe] = useState(false);
  return (
    <Form
      onFinish={(values) => mutate({ ...values, remember_me: rememberMe })}
      rootClassName={styles.form}>
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout='vertical'
        label='البريد الإلكتروني'
        name='email'
        rules={[{ required: true, message: "البريد الإلكتروني مطلوب" }]}>
        <Input type='email' placeholder='ادخل بريدك الإلكتروني' />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout='vertical'
        label={
          <div className={styles.passwordLabel}>
            <p>كلمة المرور</p>
            <CustomLink to={"/auth/forget-password"}>
              نسيت كلمة المرور؟
            </CustomLink>
          </div>
        }
        name='password'
        rules={[{ required: true, message: " كلمة المرور مطلوبة" }]}>
        <Input.Password placeholder=' ادخل كلمة المرور' />
      </Form.Item>
      <Form.Item
        rootClassName={styles.rememberMe}
        name='remember_me'
        valuePropName='checked'>
        <Checkbox
          checked={rememberMe}
          onChange={() => setRememberMe((prev) => !prev)}>
          تذكرني
        </Checkbox>
      </Form.Item>
      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type='primary'
        htmlType='submit'>
        دخول
      </CustomBtn>
      <div className={styles.footer}>
        <p>لا تملك حساب؟ </p>
        <CustomLink to={"/auth/sign-up"}>سجل الان</CustomLink>
      </div>
    </Form>
  );
}
export default SignInForm;
