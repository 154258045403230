import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";

type TProps = LinkProps;

function CustomLink(props: TProps) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Link {...props} to={`/${language}${props.to}`}>
      {props.children}
    </Link>
  );
}

export default CustomLink;
