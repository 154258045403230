/** @format */

import SignUpLottie from "../../../assets/lottie/lottie-sign-up.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import SignUpForm from "../../../components/auth/Forms/SignupForm/SignupForm";
function Page() {
  document.title = "انشاء حساب جديد";
  return (
    <AuthLayout lottie={SignUpLottie}>
      <SignUpForm formHeading=" إنشاء حساب جديد" />
    </AuthLayout>
  );
}
export default Page;
