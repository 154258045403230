/** @format */

import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProfile } from "./profile";

function useProtectedRoutes() {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const { data, isError, isFetching, isSuccess } = useGetProfile(navigate);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (isError && !pathname.includes("auth")) {
      navigate(`/${language}/auth/sign-in`);
    }

    if (!isSuccess) return;

    if (pathname.includes("auth")) {
      return;
    }

    if (data?.approval_status !== "approved") {
      if (!pathname.includes("profile")) {
        navigate(`/${language}/settings/profile/view`);
      }
    }
  }, [
    isFetching,
    isError,
    navigate,
    language,
    isSuccess,
    pathname,
    data?.approval_status,
  ]);
}
export default useProtectedRoutes;
