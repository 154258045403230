/** @format */

import { Input, Radio, Select } from "antd";
import { useGetProfile } from "../../../../hooks/profile";
import {
  TCartOption,
  TOption,
  TOptionValue,
  TProductOption,
} from "../../../../types/products";
import style from "./style.module.scss";

export default function ProductOptions({
  options,
  productId,
  // catId,
  values,

  onOptionChange,
  optionsValues,
  price,
  wholesale_price,
}: {
  options: TProductOption[];
  productId: number;
  // catId: number;
  values?: TCartOption[];
  // startTransition: any;
  // isPending: boolean;
  optionsValues: Record<string, string | number[]>;
  onOptionChange: (key: number, value: string | number[]) => void;
  price: number;
  wholesale_price: number;
}) {
  const { data: profile } = useGetProfile();
  return (
    <div>
      {wholesale_price && (
        <div className={style.topupPrice}>
          <span>سعر التجزئة : </span>
          <p>{wholesale_price.toFixed(3)} </p>
          <span className={style.currency}>
            {profile?.currency?.code || "ر.س"}
          </span>
        </div>
      )}

      {/* <div className={style.topupPrice}>
        <span>سعر الجملة: </span>
        <p>{price.toFixed(3)} </p>
        <span className={style.currency}>
          {profile?.currency?.code || "ر.س"}
        </span>
      </div> */}

      <div className={style.productOptions}>
        {options.map((option: TProductOption) => (
          <ProductOption
            option={option.option}
            onOptionChange={onOptionChange}
            optionsValues={optionsValues}
            key={`${option.id}${productId}`}
            id={option.id}
          />
        ))}
        {/* <Button isLoading={isPending} title='Save' onCLick={handleSave} /> */}
      </div>
    </div>
  );
}
const ProductOption = ({
  option,
  onOptionChange,
  optionsValues,
  id,
}: {
  option: TOption;
  onOptionChange: (key: number, value: string | number[]) => void;
  optionsValues: Record<string, string | number | number[] | undefined>;
  id: number;
}) => {
  const renderOptionType = () => {
    switch (option.type) {
      case "radio": {
        return (
          <RadioOptionType
            optionValues={option.option_values || []}
            onOptionChange={onOptionChange}
            value={optionsValues[id]}
            id={id}
          />
        );
      }
      case "text": {
        return (
          <Input
            placeholder='اكتب رقم الايدي ID'
            // className={style.textInput}
            onChange={(event) => onOptionChange(id, event.target.value)}
            value={
              optionsValues[id] ? optionsValues[id]?.toString() : undefined
            }
          />
        );
      }
      case "checkbox": {
        return (
          <CheckboxOptionType
            optionValues={option.option_values || []}
            onOptionChange={onOptionChange}
            value={optionsValues[id]}
            id={id}
          />
        );
      }
      case "textarea": {
        return (
          <textarea
            className={style.textInput}
            onChange={(event) => onOptionChange(id, event.target.value)}
            value={
              optionsValues[id] ? optionsValues[id]?.toString() : undefined
            }
          />
        );
      }
      case "select": {
        return (
          <SelectOptionType
            optionValues={option.option_values || []}
            onOptionChange={onOptionChange}
            value={optionsValues[id]}
            id={id}
            label={option.name}
          />
        );
      }
    }
  };
  return (
    <div className={style.optionCont}>
      <label>
        {option.name} <span>*</span>
      </label>
      {renderOptionType()}
    </div>
  );
};
const RadioOptionType = ({
  optionValues,
  onOptionChange,
  value,
  id,
}: {
  optionValues: TOptionValue[];
  onOptionChange: (key: number, value: string | number[]) => void;
  value?: string | number | number[];
  id: number;
}) => {
  return (
    <div className={style.optionsCont} key={id}>
      <Radio.Group value={value && value.constructor === Array ? value[0] : 0}>
        {optionValues.map((optionValue: any) => (
          <div
            key={`${optionValue.id}${id}`}
            onClick={() => onOptionChange(id, [optionValue.id])}>
            <Radio key={`${optionValue.id}${id}`} value={optionValue.id}>
              {optionValue.name}
            </Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );
};
const CheckboxOptionType = ({
  optionValues,
  onOptionChange,
  value,
  id,
}: {
  optionValues: TOptionValue[];
  onOptionChange: (key: number, value: string | number[]) => void;
  value?: string | number | number[];
  id: number;
}) => {
  const handleChange = (checkedId: number) => {
    if (Array.isArray(value) && value?.includes(checkedId)) {
      onOptionChange(
        id,
        value.filter((item) => item !== checkedId)
      );
    } else {
      onOptionChange(
        id,
        Array.isArray(value) ? [...value, checkedId] : [checkedId]
      );
    }
  };
  return (
    <div className={style.optionsCont}>
      {optionValues.map((optionValue: any) => (
        <div key={optionValue.id}>
          <input
            className={style.radioAndCheckboxInput}
            type='checkbox'
            checked={Array.isArray(value) && value?.includes(optionValue.id)}
            onChange={() => handleChange(optionValue.id)}
            id='flexCheckDefault'
          />
          <label>{optionValue.name}</label>
        </div>
      ))}
    </div>
  );
};
const SelectOptionType = ({
  optionValues,
  onOptionChange,
  id,
  value,
  label,
}: {
  optionValues: TOptionValue[];
  onOptionChange: (key: number, value: string | number[]) => void;
  id: number;
  value: any;
  label: string;
}) => {
  return (
    <div className={style.selectCont}>
      <Select
        placeholder='كل الأقسام '
        allowClear
        style={{ width: "100%" }}
        onChange={(event) => onOptionChange(id, [event])}
        options={
          optionValues
            ? optionValues.map((option) => ({
                label: <span>{option.name}</span>,
                value: option.id,
              }))
            : []
        }
        value={value}
        showSearch
      />
      {/* <Select
        value={
          value
            ? {
                value: value[0],
                label: optionValues.find((item) => item.id === value[0])?.name,
              }
            : null
        }
        placeholder={`Please select ${label}...`}
        onChange={(event) => onOptionChange(id, [event?.value])}
        styles={{
          singleValue: (provided, { data }) => ({
            ...provided,
            color: "#fff",
            // specify a fallback color here for those values not accounted for in the styleMap
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "rgba(64, 12, 96, 0.4)",
            outline: "none",
            width: "100%",
            color: "#fff",
            borderColor: state.isFocused ? "#712da6" : "#712da6",
          }),
        }}
        options={optionValues.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
      /> */}
    </div>

    // <select
    //   value={value || "disabled"}
    //   onChange={(event) => onOptionChange(id, [Number(event.target.value)])}>
    //   <option key={"disabled"} value={"disabled"} disabled>
    //     Please select option
    //   </option>
    //   {optionValues.map((optionValue: any) => (
    //     <option key={optionValue.id} value={optionValue.id}>
    //       {optionValue.name}
    //     </option>
    //   ))}
    // </select>
  );
};
