/** @format */

import { Button } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useMainCategory, useNewSubCategory } from "../../../hooks/store";
import { TBrand, TMainCategory } from "../../../types/store";
import CustomLink from "../../Common/CustomLink/CustomLink";
import PaginationCom from "../../Common/PaginationComp";
import Spinner from "../../Common/Spinner";
import BrandFilter from "./BrandFilter";
import style from "./style.module.scss";

export type TFilters = {
  category_filter: string;
  name: string;
  page: string;
  is_brands?: string;
};
export default function Brands() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<TFilters>({
    category_filter: searchParams.get("category_filter") || "",
    name: searchParams.get("name") || "",
    page: searchParams.get("page") || "1",
    is_brands: "1",
  });

  const getFilterChanges = (stateName: string, value: string) => {
    setSearchParams({
      ...filters,
      [stateName]: value ? value.toString() : "",
      page: "1",
    });
    setFilters({ ...filters, [stateName]: value, page: "1" });
  };
  const { page } = filters;
  const gatPage = (page: number) => {
    setSearchParams({ ...filters, page: page.toString() });
    setFilters({ ...filters, page: page.toString() });
  };
  const { data, isLoading } = useNewSubCategory(filters, Number(page));
  // const { data, isLoading } = useBrands(filters, Number(page));
  const { data: mainCategories } = useMainCategory();
  return (
    <div className={style.barndCont}>
      <BrandFilter
        mainCategories={mainCategories}
        filters={filters}
        getFilterChanges={getFilterChanges}
      />
      {!isLoading && data ? (
        <>
          <div className={style.header}>
            <h4>اختر العلامة التجارية:</h4>
            <span>{data?.total} علامة تجارية</span>
          </div>
          <div className={style.brandsCard}>
            {data
              ? data.data.map((brand: TMainCategory) => (
                  <BrandCard
                    brand={brand.brand}
                    key={brand.id}
                    childCount={brand.child_count}
                    categoryName={brand.parent?.name || ""}
                    category_id={brand.id || brand.parent?.id}
                    data={brand}
                  />
                ))
              : null}
          </div>

          <div className={clsx(style.paginationCont, "paginationCont")}>
            <PaginationCom
              setPage={gatPage}
              currentPage={Number(page)}
              total={data?.total}
            />
            <p>
              عرض {(Number(page) - 1) * 10 + 1} إلى {Number(page) * 10} من{" "}
              {data?.total} علامة تجارية
            </p>
          </div>
        </>
      ) : (
        <div className={style.brandSpinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export const BrandCard = ({
  brand,
  childCount,
  category_id,
  categoryName,
  data,
}: {
  brand: TBrand;
  childCount: number;
  category_id: number;
  categoryName: string;
  data?: any;
}) => {
  const prepareUrl = () => {
    if (childCount === 0) {
      return `/store/brand/${brand?.id || category_id}/category/${category_id}`;
    } else {
      return `/store/brand/${
        brand?.id || category_id
      }/sub-category/${category_id}`;
    }
  };
  return (
    <CustomLink to={prepareUrl()}>
      <div className={style.card}>
        <div className={style.imgCont}>
          <img
            src={brand?.images?.[0]?.image || data.image}
            alt={brand?.name}
          />
          <Button>{brand?.name || data.name}</Button>
        </div>
        <h2>{brand?.name || data.name}</h2>
        <div className={style.productCount}>
          <p>
            {categoryName || "-"}
            {/* المنتجات: {brand.products_count} بطاقة <FaTag /> */}
          </p>
        </div>
      </div>
    </CustomLink>
  );
};
