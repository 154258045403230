import { TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAuthorization from "../../hooks/useAuthorization";
import CustomTabs from "../Common/CustomTabs/CustomTabs";
import SectionCard from "../Common/SectionCard/SectionCard";
import styles from "./index.module.scss";
import ListEmployees from "./ListEmployees/ListEmployees";
import ListRoles from "./ListRoles/ListRoles";

function EmployeesContent() {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(hash || "#employees");
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    if (hash) {
      setActiveTab(hash);
    }
  }, [hash]);

  const items: TabsProps["items"] = [
    {
      key: "#employees",
      label: " قائمة الموظفين ",
      children: <ListEmployees />,
    },
    {
      key: "#roles",
      label: "مجموعات الموظفين  ",
      children: <ListRoles />,
    },
  ];

  if (!hasPermission("SettingAdminList")) items.shift();
  if (!hasPermission("SettingRolesList")) items.pop();

  return (
    <div className={styles.employeesContent}>
      <SectionCard>
        <CustomTabs
          rootClassName={styles.tabs}
          isTabBold
          defaultActiveKey={activeTab}
          items={items}
        />
      </SectionCard>
    </div>
  );
}
export default EmployeesContent;
