/** @format */

import React from "react";
import style from "./style.module.scss";

export default function Box({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement;
}) {
  return (
    <div className={style.boxCont}>
      <h6>{title}</h6>
      {children}
    </div>
  );
}
