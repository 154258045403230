/** @format */

import { THome } from "../../../types/products";
import Box from "../../Common/Box";
import style from "./style.module.scss";
import ExportTickets from "./Tickets";
import TransactionsTable from "./TransactionsTable";

export default function RechargeAndTickets({ data }: { data: THome }) {
  return (
    <div className={style.cont}>
      <Box title='عمليات شحن الرصيدالنقدي (المعلقة)'>
        <TransactionsTable transactions={data.wallet_cash_transactions} />
      </Box>
      <Box title='الشكاوى المعلقة'>
        <ExportTickets data={data.support_tickets} />
      </Box>
    </div>
  );
}
