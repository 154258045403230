/** @format */

import { Tag } from "antd";
import { LuMapPin } from "react-icons/lu";
import { sellerStatus } from "../../../constant/sellerStatus";
import { TUser } from "../../../types/auth";
import styles from "./ProfileHeader.module.scss";

type TProps = {
  data?: TUser;
};
function ProfileHeader({ data }: TProps) {
  const showAddress = data?.country || data?.city;

  return (
    <div className={styles.profileHeader}>
      <div className={styles.content}>
        <div className={styles.profileInfo}>
          <div className={styles.imgBox}>
            <img src={data?.logo} alt='profile' width={96} height={96} />
          </div>
          <div className={styles.details}>
            <h3 className={styles.name}>{data?.name}</h3>
            {showAddress && (
              <p className={styles.location}>
                <LuMapPin />
                <span>
                  {data?.country?.name} {data?.city?.name}
                </span>
              </p>
            )}
            <div className={styles.status}>
              <b>حالة المستخدم: </b>
              <Tag
                className={styles.tag}
                color={
                  sellerStatus[
                    data?.approval_status as keyof typeof sellerStatus
                  ]?.color
                }>
                {
                  sellerStatus[
                    data?.approval_status as keyof typeof sellerStatus
                  ]?.label
                }
              </Tag>
            </div>
          </div>
        </div>
        <div className={styles.balance}>
          <span className={styles.label}>الرصيد</span>
          <h4 className={styles.amount}>{Number(data?.balance)?.toFixed(2)}</h4>
          <span className={styles.currency}>
            {data?.currency?.code || "ر.س"}{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
