import { Divider, Form, FormInstance, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import clsx from "clsx";
import { useState } from "react";
import { IoMailUnreadOutline } from "react-icons/io5";
import { PhoneInput } from "react-international-phone";
import {
  useGetAllCities,
  useGetAllCountries,
  useGetAllRegions,
} from "../../../hooks/lookups";
import { TUser } from "../../../types/auth";
import styles from "./EditDetails.module.scss";

type TProps = {
  form: FormInstance<any>;
  data?: TUser;
};
function EditDetails({ form, data }: TProps) {
  const [address, setAddress] = useState({
    country_id: form.getFieldValue("country_id"),
    region_id: form.getFieldValue("region_id"),
    city_id: form.getFieldValue("city_id"),
  });
  const [countryCode, setCountryCode] = useState("");
  const [inputPhoneHasError, setInputPhoneHasError] = useState(false);
  const {
    data: countries,
    isLoading: countriesLoading,
    isSuccess: countriesIsSuccess,
  } = useGetAllCountries();

  const {
    data: regions,
    isLoading: regionsLoading,
    isSuccess: regionsIsSuccess,
  } = useGetAllRegions();
  const {
    data: cities,
    isLoading: citiesLoading,
    isSuccess: citiesIsSuccess,
  } = useGetAllCities();

  const countriesOptions =
    countries?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  const regionsOptions =
    regions
      ?.filter((region) =>
        address.country_id ? region.country_id === +address.country_id : true
      )
      .map((region) => ({
        label: region.name,
        value: region.id,
      })) || [];

  const citiesOptions =
    cities
      ?.filter((city) =>
        address.region_id ? city.region_id === +address.region_id : true
      )
      .map((country) => ({
        label: country.name,
        value: country.id,
      })) || [];

  console.log({ citiesOptions });
  return (
    <div className={clsx(styles.editDetails, "editDetails")}>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="اسم الشركة "
        name="name"
        rules={[{ required: true, message: " اسم الشركة  مطلوب" }]}
      >
        <Input placeholder="ادخل اسم الشركة " />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label=" مالك الشركة/ المدير المسئول"
        name="owner_name"
        rules={[
          { required: true, message: " مالك الشركة/ المدير المسئول مطلوب" },
        ]}
      >
        <Input placeholder="ادخل  مالك الشركة/ المدير المسئول" />
      </Form.Item>
      <Form.Item
        rootClassName={clsx(
          styles.inputGroup,
          styles.phoneInput,
          inputPhoneHasError && styles.error
        )}
        layout="vertical"
        label="رقم الهاتف"
        name="phone"
      >
        <PhoneInput
          name="phone"
          value={form.getFieldValue("phone")}
          defaultCountry="sa"
          onChange={(value, { country }) => {
            // setCountryCode("+" + country.dialCode);
            form.setFieldValue("phone", value);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="البريد الإلكتروني"
        name="email"
        rules={[{ required: true, message: "البريد الإلكتروني مطلوب" }]}
      >
        <Input
          suffix={<IoMailUnreadOutline />}
          type="email"
          placeholder="ادخل بريدك الإلكتروني"
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label=" رقم السجل التجاري "
        name="commercial_register_number"
        rules={[{ required: true, message: "رقم السجل التجاري" }]}
      >
        <Input placeholder="  ادخل رقم السجل التجاري " />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label=" رقم البطاقة الضريبية "
        name="tax_card_number"
        rules={[{ required: true, message: " رقم البطاقة الضريبية" }]}
      >
        <Input placeholder="  ادخل رقم البطاقة الضريبية " />
      </Form.Item>

      <Divider style={{ marginBottom: 0 }} />
      <h3 className={styles.addressTitle}>العنوان:</h3>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="الدولة "
        name="country_id"
      >
        <Select
          style={{ width: "100%" }}
          placeholder="اختر الدولة "
          options={countriesOptions}
          loading={countriesLoading}
          onChange={(val) => {
            setAddress({ city_id: "", region_id: "", country_id: val });
            form.setFieldValue("country_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="المنطقة "
        name="region_id"
      >
        <Select
          disabled={!address.country_id}
          style={{ width: "100%" }}
          placeholder="اختر المنطقة "
          options={regionsOptions}
          loading={regionsLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: "", region_id: val });
            form.setFieldValue("region_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="المدينة "
        name="city_id"
      >
        <Select
          disabled={!address.region_id}
          style={{ width: "100%" }}
          placeholder="اختر المدينة "
          options={citiesOptions}
          loading={citiesLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: val });
            form.setFieldValue("city_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputTextArea}
        layout="vertical"
        label="  عنوان الشركة بالتفصيل "
        name="address"
        rules={[{ required: true, message: " عنوان الشركة بالتفصيل  مطلوب" }]}
      >
        <TextArea
          rootClassName={styles.textArea}
          placeholder="اكتب  عنوان الشركة"
        />
      </Form.Item>
    </div>
  );
}

export default EditDetails;
