/** @format */

import CustomSkelton from "../../../components/CustomSkelton/CustomSkelaton";
import EditProfileForm from "../../../components/profile/EditProfileForm/EditProfileForm";
import { useGetProfile } from "../../../hooks/profile";

function EditProfile() {
  const { data, isLoading } = useGetProfile();
  document.title = "تعديل الملف الشخصي";
  if (isLoading) return <CustomSkelton />;
  return <EditProfileForm data={data} isLoading={isLoading} />;
}

export default EditProfile;
