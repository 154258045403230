/** @format */
import { Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaHeart, FaRegHeart } from "react-icons/fa";

import { RiShoppingBasket2Line } from "react-icons/ri";
import { useGetProfile } from "../../../hooks/profile";
import {
  useAddToCart,
  useAddToFav,
  useCart,
  useDeleteFromFav,
} from "../../../hooks/store";
import useAuthorization from "../../../hooks/useAuthorization";
import { TCart, TFavProduct, TStoreProduct } from "../../../types/products";
import ProductOptions from "../Products/ProductOptions";

import style from "./style.module.scss";
export default function ProductList({
  data,
  total,
  filters,
  saveFilters,
  categoryId,
  isTopup,
  perPage = 10,
}: {
  data: TStoreProduct[] | TFavProduct[];
  total: number;
  saveFilters: (stateName: string, value: string) => void;
  filters: { page: string; search: string };
  categoryId?: number;
  isTopup?: number;
  perPage?: number;
}) {
  const { data: cartData, isLoading, refetch, isFetching } = useCart();
  return (
    <div className={style.productsCont}>
      <Input
        placeholder='ابحث عن بطاقة...'
        prefix={<CiSearch />}
        onChange={(event) => saveFilters("search", event.target.value)}
        value={filters.search}
      />
      <div className={style.header}>
        <h4>اختر البطاقة:</h4>
        {/* <p>
          عرض {(Number(filters.page) - 1) * perPage + 1} إلى{" "}
          {Number(filters.page) * perPage} من {total} بطاقة
        </p> */}
      </div>

      {data?.map((product) => (
        <ProductCard
          cartData={cartData}
          key={product.id}
          product={"product" in product ? product.product : product}
          categoryId={categoryId}
          isTopup={isTopup ? isTopup : product.is_topup}
        />
      ))}
    </div>
  );
}
const ProductCard = ({
  product,
  categoryId,
  isTopup,
  cartData,
}: {
  product: TStoreProduct;
  categoryId?: number;
  isTopup: number;
  cartData?: TCart;
}) => {
  const [quantity, setQuantity] = useState<number>(1);
  const { mutate, isLoading: addToFavLoading } = useAddToFav();
  const [optionsValues, setValues] = useState<
    Record<string, string | number[]>
  >({});
  const { data: profile } = useGetProfile();
  const { mutate: deleteFav, isLoading: deleteFavLoading } = useDeleteFromFav();
  const { mutate: addItemToCart, isLoading: addToCartLoading } = useAddToCart();
  const { hasPermission } = useAuthorization();
  const addToCart = () => {
    const payload = {
      quantity,
      product_id: product.id,
      category_id: categoryId || 0,
      product_options: Object.keys({ ...optionsValues }).map((key) => {
        return {
          id: Number(key),
          ...(typeof optionsValues[key] === "string"
            ? { value: optionsValues[key] }
            : { option_value_ids: optionsValues[key] }),
        };
      }),
    };

    addItemToCart(payload);
  };
  const onOptionChange = (key: number, value: string | number[]) => {
    setValues({ ...optionsValues, [key.toString()]: value });
  };
  useEffect(() => {
    const productCartData = cartData?.cart_products.find(
      (cartItem) =>
        cartItem.product_id === product.id &&
        cartItem.category_id === categoryId
    );
    if (cartData && productCartData) {
      if (productCartData?.options.length) {
        const values = productCartData?.options;
        let optionsValuesInit = {};

        values.forEach((value) => {
          optionsValuesInit = {
            ...optionsValuesInit,
            [value.product_option_id]: value.value
              ? value.value
              : value.cart_product_option_values.map(
                  (item) => item.option_value.id
                ),
          };
        });

        setValues(optionsValuesInit);
      } else {
        setQuantity(productCartData.quantity);
      }
    } else {
      setValues({});
      setQuantity(1);
    }
  }, [cartData]);

  return (
    <Spin spinning={addToFavLoading || deleteFavLoading}>
      <div className={style.card}>
        <div className={style.product}>
          <img src={product.image} alt={product.name} />
          <div className={style.productInfo}>
            <h6>{product.name || "-"} </h6>
            <div className={style.topupPrice}>
              <span>سعر الجملة : </span>
              <p>{product.price.toFixed(3)} </p>
              <span className={style.currency}>
                {profile?.currency?.code || "ر.س"}
              </span>
            </div>
            {product.is_available ? (
              <div className={style.priceAndCart}>
                {isTopup && product.product_options.length ? (
                  <ProductOptions
                    wholesale_price={product.wholesale_price}
                    price={product.price}
                    options={product.product_options}
                    productId={product.id}
                    onOptionChange={onOptionChange}
                    optionsValues={optionsValues}
                  />
                ) : (
                  <div className={style.priceAndCount}>
                    <div className={style.priceAndTotalPrice}>
                      <label>سعر التجزئة:</label>
                      <span className={style.price}>
                        <span>
                          {Number(product?.wholesale_price).toFixed(2)}
                        </span>{" "}
                        {profile?.currency?.code}
                      </span>
                    </div>
                    <div className={style.count}>
                      <button
                        disabled={quantity === 1}
                        onClick={() => setQuantity(quantity - 1)}>
                        -
                      </button>
                      <input
                        value={quantity}
                        type={"number"}
                        onChange={(event) =>
                          setQuantity(Number(event.target.value))
                        }
                      />
                      <button onClick={() => setQuantity(quantity + 1)}>
                        +
                      </button>
                    </div>
                    <div className={style.priceAndTotalPrice}>
                      <label>الإجمالي :</label>
                      <span>
                        <b>{(product.wholesale_price * quantity).toFixed(2)}</b>{" "}
                        {profile?.currency?.code || "ر.س"}{" "}
                      </span>
                    </div>
                  </div>
                )}
                <div />
                {hasPermission("AddProductFav") ? (
                  <div className={style.cart} onClick={addToCart}>
                    {addToCartLoading ? <Spin /> : <RiShoppingBasket2Line />}
                  </div>
                ) : null}
              </div>
            ) : (
              <p className={style.oos}>إنتهى من المخزن</p>
            )}
          </div>
          {product.is_favorite ? (
            hasPermission("DeleteProductFav") ? (
              <FaHeart
                className={style.filledHeart}
                onClick={() => {
                  deleteFav({
                    product_id: product.id,
                    category_id: categoryId || 0,
                  });
                }}
              />
            ) : null
          ) : hasPermission("AddProductFav") ? (
            <FaRegHeart
              className={style.filledHeart}
              onClick={() =>
                mutate({
                  product_id: product.id,
                  category_id: categoryId || 0,
                })
              }
            />
          ) : null}

          {/* {product.is_topup ? ( */}

          {/* ) : null} */}
        </div>
      </div>
    </Spin>
  );
};
