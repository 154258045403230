/** @format */

import { Form, Input, Select } from "antd";

import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "../../../../hooks/auth";
import {
  useGetAllCities,
  useGetAllCountries,
  useGetAllRegions,
} from "../../../../hooks/lookups";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import styles from "../Forms.module.scss";

function SignUpForm({ formHeading }: { formHeading: string }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [inputPhoneHasError, setInputPhoneHasError] = useState(false);

  const [address, setAddress] = useState({
    country_id: form.getFieldValue("country_id"),
    region_id: form.getFieldValue("region_id"),
    city_id: form.getFieldValue("city_id"),
  });
  const {
    i18n: { language },
  } = useTranslation();

  const {
    data: countries,
    isLoading: countriesLoading,
    isSuccess: countriesIsSuccess,
  } = useGetAllCountries();

  const {
    data: regions,
    isLoading: regionsLoading,
    isSuccess: regionsIsSuccess,
  } = useGetAllRegions();
  const {
    data: cities,
    isLoading: citiesLoading,
    isSuccess: citiesIsSuccess,
  } = useGetAllCities();

  const countriesOptions =
    countries?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  const regionsOptions =
    regions
      ?.filter((region) =>
        address.country_id ? region.country_id === +address.country_id : true
      )
      .map((region) => ({
        label: region.name,
        value: region.id,
      })) || [];

  const citiesOptions =
    cities
      ?.filter((city) =>
        address.region_id ? city.region_id === +address.region_id : true
      )
      .map((country) => ({
        label: country.name,
        value: country.id,
      })) || [];

  const { mutate, isLoading } = useSignUp(navigate);
  return (
    <Form
      onFinish={(values) => {
        console.log({ values });
        sessionStorage.setItem("registerData", JSON.stringify(values));
        mutate(values);
      }}
      form={form}
      rootClassName={styles.form}
    >
      <h2 className={styles.formHeading}>{formHeading}</h2>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="اسم الشركة"
        name="name"
        rules={[{ required: true, message: "  اسم الشركة مطلوب" }]}
      >
        <Input placeholder=" اكتب اسم الشركة" />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="اسم المالك"
        name="owner_name"
        rules={[{ required: true, message: "  اسم المالك مطلوب" }]}
      >
        <Input placeholder=" اكتب اسم المالك" />
      </Form.Item>
      <Form.Item
        rootClassName={clsx(
          styles.inputGroup,
          styles.phoneInput,
          inputPhoneHasError && styles.error
        )}
        layout="vertical"
        label="رقم الهاتف"
        name="phone"
      >
        <PhoneInput
          name="phone"
          value={form.getFieldValue("phone")}
          defaultCountry="sa"
          onChange={(value, { country }) => {
            // setCountryCode("+" + country.dialCode);
            form.setFieldValue("phone", value);
          }}
        />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="الدولة "
        name="country_id"
        rules={[{ required: true, message: "   الدولة مطلوب" }]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="اختر الدولة "
          options={countriesOptions}
          loading={countriesLoading}
          onChange={(val) => {
            setAddress({ city_id: "", region_id: "", country_id: val });
            form.setFieldValue("country_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="المنطقة "
        name="region_id"
        rules={[{ required: true, message: "   المنطقة مطلوب" }]}
      >
        <Select
          disabled={!address.country_id}
          style={{ width: "100%" }}
          placeholder="اختر المنطقة "
          options={regionsOptions}
          loading={regionsLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: "", region_id: val });
            form.setFieldValue("region_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="المدينة "
        name="city_id"
        rules={[{ required: true, message: "   المدينة مطلوب" }]}
      >
        <Select
          disabled={!address.region_id}
          style={{ width: "100%" }}
          placeholder="اختر المدينة "
          options={citiesOptions}
          loading={citiesLoading}
          onChange={(val) => {
            setAddress({ ...address, city_id: val });
            form.setFieldValue("city_id", val);
          }}
        />
      </Form.Item>
      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="البريد الإلكتروني"
        name="email"
        rules={[{ required: true, message: "البريد الإلكتروني مطلوب" }]}
      >
        <Input type="email" placeholder="example@mail.com" />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="كلمة المرور"
        name="password"
        rules={[{ required: true, message: " كلمة المرور مطلوبة" }]}
      >
        <Input.Password placeholder=" ادخل كلمة المرور" />
      </Form.Item>

      <Form.Item
        rootClassName={styles.inputGroup}
        layout="vertical"
        label="تأكيد كلمة المرور"
        name="password_confirmation"
        rules={[
          { required: true, message: " تأكيد كلمة المرور  مطلوبة" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error("كلمة المرور غير متطابقة"));
            },
          }),
        ]}
      >
        <Input.Password placeholder=" ادخل كلمة المرور" />
      </Form.Item>

      <div className={clsx(styles.footer, styles.note)}>
        <p>
          من خلال التسجيل فإنك توافق على شروط الاستخدام الخاصة بـ Take Card{" "}
          <CustomLink to={`/auth/sign-up`}>الشروط والأحكام</CustomLink>
        </p>
      </div>
      <CustomBtn
        disabled={isLoading}
        loading={isLoading}
        rootClassName={styles.submit}
        type="primary"
        htmlType="submit"
      >
        متابعة
      </CustomBtn>
      <div className={styles.footer}>
        <p>هل لديك حساب بالفعل؟ </p>
        <CustomLink to={`/auth/sign-in`}>تسجيل الدخول</CustomLink>
      </div>
    </Form>
  );
}
export default SignUpForm;
