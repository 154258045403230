/** @format */

import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TBanks, TProduct, TTransactions } from "../types/products";
import api from "./api";

export const cashService = {
  addReceipt: async (
    payload: FormData
  ): Promise<AxiosResponse<TResponse<TProduct[]>>> =>
    api.post("/balance-recharge", payload),
  getBalanceList: async (): Promise<
    AxiosResponse<TResponse<TTransactions[]>>
  > => api.get("/wallet-transactions"),
  getBanks: async (): Promise<AxiosResponse<TResponse<TBanks[]>>> =>
    api.get("/banks"),
};
