import { useQuery } from "react-query";
import { reportsService } from "../services/reports";
import { tags } from "../services/tags";
import { TReportParams } from "../types/api";

export const useGetReportProducts = (params: TReportParams) => {
  const query = useQuery(tags.getReportsProducts, async () => {
    const { data } = await reportsService.getReportProducts(params);
    return data.data;
  });
  return { ...query };
};

export const useGetOrderProductsReport = (params: TReportParams) => {
  const query = useQuery(tags.getReportsOrders, async () => {
    const { data } = await reportsService.getOrderProductsReport(params);
    return data.data;
  });
  return { ...query };
};

export const useGetOrdersReport = (params: TReportParams) => {
  const query = useQuery(tags.getReportsOrderDetails, async () => {
    const { data } = await reportsService.getOrdersReport(params);
    return data;
  });
  return { ...query };
};
