import { TEmployee } from "../../../types/employees";
import CustomSkelton from "../../CustomSkelton/CustomSkelaton";
import EmployeeCard from "../EmployeeCard/EmploeeCard";
import styles from "./ListEmployeeTable.module.scss";

type TProps = {
  data?: TEmployee[];
  isLoading: boolean;
};

function ListEmployeeTable({ data = [], isLoading }: TProps) {
  if (isLoading) return <CustomSkelton />;

  const renderData = data?.length ? data : [];
  return (
    <div className={styles.employeesContainer}>
      {renderData?.map((employee) => (
        <EmployeeCard key={employee.id} data={employee} />
      ))}
    </div>
  );
}

export default ListEmployeeTable;
