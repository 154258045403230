/** @format */

import ViewProfileContent from "../../../components/profile/ViewProfileContent/ViewProfileContent";

function ViewProfile() {
  document.title = "الاعدادات | الملف الشخصي";
  return <ViewProfileContent />;
}

export default ViewProfile;
