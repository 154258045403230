import { Skeleton } from "antd";
import styles from "./CustomSkelton.module.scss";
function CustomSkelton() {
  return (
    <div className={styles.customSkelton}>
      {Array(6)
        .fill(0)
        .map((_, index) => (
          <Skeleton key={index} active />
        ))}
    </div>
  );
}

export default CustomSkelton;
