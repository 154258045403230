/** @format */
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";
import Box from "../Common/Box/index";

import type { TableProps } from "antd";
import { Table } from "antd";
import { useGetBalance } from "../../hooks/useCash";
import { TTransactions } from "../../types/products";
import style from "./style.module.scss";

export default function Balance() {
  const { data, isLoading } = useGetBalance();
  const columns: TableProps<TTransactions>["columns"] = [
    {
      title: "الرقم التعريفي",
      dataIndex: "id",
      key: "id",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "تاريخ العملية",
      dataIndex: "created_at",
      key: "created_at",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#e0e9fa",
          fontSize: "14px",
        },
      }),
    },
    {
      title: "الوصف",
      dataIndex: "recharge_balance_type",
      key: "recharge_balance_type",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#e0e9fa",
          fontSize: "14px",
          padding: "12px 10px",
          lineHeight: "1px",
        },
      }),
    },
    {
      title: "الإجراء",
      dataIndex: "type",
      key: "type",
      render: (text: string) => (
        <p
          className={
            text === "add"
              ? style.balanceStatusAdd
              : style.balanceStatusWithdraw
          }>
          {text} {text === "add" ? <IoMdArrowUp /> : <IoMdArrowDown />}
        </p>
      ),
    },
    {
      title: "المبلغ",
      key: "status",
      dataIndex: "status",
      render: (text: number) => <b>{text}</b>,
    },
    // {
    //   title: "العمله",
    //   key: "currency",
    //   dataIndex: "currency",
    //   render: (text: TCurrency) => <b>{text.name}</b>,
    // },
    {
      title: "المبلغ",
      key: "amount",
      dataIndex: "amount",
      render: (text: number, record) => (
        <b>
          {text.toFixed(3)} {record.currency.code}
        </b>
      ),
    },
  ];

  return (
    <div className={style.balanceCont}>
      <Box title='عمليات شحن الرصيد'>
        <div>
          <Table<TTransactions>
            rowKey={(row) => row.id}
            className={style.balanceTable}
            columns={columns}
            pagination={false}
            dataSource={data}
            loading={isLoading}
            scroll={{ x: "max-content" }}
          />
        </div>
      </Box>
    </div>
  );
}
