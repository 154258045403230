/** @format */

import { AxiosResponse } from "axios";
import { TResponse } from "../types/api";
import { TUser } from "../types/auth";
import api from "./api";

const profileServices = {
  getProfile: async (): Promise<AxiosResponse<TResponse<TUser>>> =>
    api.get("/profile"),

  updateProfile: async (
    body: FormData
  ): Promise<AxiosResponse<TResponse<TUser>>> =>
    api.post("/update/profile-data", body),

  deleteAttachment: async (id: number): Promise<AxiosResponse<TResponse<[]>>> =>
    api.delete(`/attachment/delete/${id}`),
};

export default profileServices;
