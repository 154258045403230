import clsx from "clsx";
import styles from "./SectionCard.module.scss";

type TProps = {
  children: React.ReactNode;
  title?: string;
  rootClassName?: string;
};

function SectionCard({ children, title, rootClassName }: TProps) {
  return (
    <section className={clsx(styles.sectionCard, rootClassName)}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.content}>{children}</div>
    </section>
  );
}

export default SectionCard;
