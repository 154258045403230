/** @format */
import { IoCopyOutline } from "react-icons/io5";
import QRCode from "react-qr-code";
import CustomBtn from "../../../Common/CustomBtn/CustomBtn";
import CustomLink from "../../../Common/CustomLink/CustomLink";
import style from "./style.module.scss";

export default function GoogleAuth() {
  return (
    <div className={style.googleAuthCont}>
      <div>
        <h2 className={style.formHeading}>تسجيل موثقي جوجل</h2>
        <p className={style.msg}>
          سوف تحتاج إلى <span>Google Authenticator</span> لإكمال هذه العملية
        </p>
      </div>
      <div className={style.qrCont}>
        <QRCode
          value={sessionStorage.getItem("qrCodeUrl") || ""}
          size={150}
          style={{ height: "auto", margin: "auto" }}
        />
        <p>قم بمسح رمز QR في تطبيقك</p>
      </div>
      <div className={style.code}>
        <p>أو ادخل الكود يدويا</p>
        <div className={style.copyCode}>
          <IoCopyOutline
            onClick={() => navigator.clipboard.writeText("test copy")}
          />
          <p>{sessionStorage.getItem("secret")}</p>
        </div>
      </div>
      <div className={style.actionBtns}>
        <CustomBtn type='primary' rootClassName={style.cancelBtn}>
          <CustomLink to='/auth/sign-up'>إلغاء</CustomLink>
        </CustomBtn>
        <CustomBtn type='primary'>
          <CustomLink to='/auth/google-sign-up-verify'>متابعة</CustomLink>
        </CustomBtn>
      </div>
    </div>
  );
}
