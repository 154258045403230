/** @format */

import { AxiosResponse } from "axios";
import { TFilters } from "../components/Store/Brands";
import { TResponse, TResponseData } from "../types/api";
import { TCart, TFavProduct } from "../types/products";
import {
  TAddToCart,
  TAddToFavPayload,
  TCategory,
  TDeleteFromCart,
  TDeleteFromFavPayload,
  TMainCategory,
} from "../types/store";
import api from "./api";

export const storeService = {
  getBrands: async (
    filters: TFilters,
    currentPage: number
  ): Promise<AxiosResponse<any>> =>
    api.get(
      `/store/brands?name=${filters.name}&category_filter=${filters.category_filter}&page=${currentPage}`
    ),
  getNewSubCategory: async (
    filters: TFilters,
    currentPage: number
  ): Promise<AxiosResponse<any>> =>
    api.get(
      `/store/categories/subs?name=${filters.name}&parent_id=${
        filters.category_filter || ""
      }&page=${currentPage}&is_brands=${filters.is_brands || "0"}`
    ),
  getMainCategory: async (): Promise<
    AxiosResponse<TResponse<TMainCategory[]>>
  > => api.get("/store/categories/main"),
  getCarts: async (): Promise<AxiosResponse<TResponse<TCart>>> =>
    api.get("/store/cart/get"),

  getCategory: async (
    categoryId?: string,
    brandId?: string
  ): Promise<AxiosResponse<TResponse<TCategory[]>>> =>
    api.get(`/store/categories/subs/${categoryId}?brand_id=${brandId || ""}`),
  getFavProducts: async (
    filters: Record<string, string>
  ): Promise<AxiosResponse<TResponseData<TFavProduct>>> =>
    api.get(
      `/store/favorites/get/products?page=${filters.page}&per_page=${filters.pageSize}`
    ),
  getProducts: async (
    categoryId: string,
    brandId: string,
    page: string,
    search: string
  ): Promise<AxiosResponse<any>> =>
    api.get(
      `store/products/category/${categoryId}/brand/${brandId}?page=${page}&search=${search}`
    ),
  getNewProducts: async (
    categoryId: string,
    brandId: string,
    page: string,
    search: string,
    per_page: string
  ): Promise<AxiosResponse<any>> =>
    api.get(
      `store/products/category/${categoryId}?page=${page}&search=${search}&brand_id=${brandId}&per_page=${per_page}`
    ),
  addToFav: async (body: TAddToFavPayload) =>
    api.post("/store/favorites/add-product-to-fav", body),
  deleteFromFav: async (body: TDeleteFromFavPayload) =>
    api.delete(
      `/store/favorites/delete/product/${body.product_id}/category/${body.category_id}`
    ),
  addToCart: async (body: TAddToCart) =>
    api.post("/store/cart/add-product-to-cart", body),
  deleteFrom: async (body: TDeleteFromCart) =>
    api.delete(
      `/store/cart/deleteProduct/${body.product_id}/category/${body.category_id}`
    ),

  createOrder: async () => api.post("/store/create-order"),
};
