/** @format */

import StorePage from "../../components/Store";
import CategoryComp from "../../components/Store/Category";

export default function Category() {
  return (
    <StorePage>
      <CategoryComp />
    </StorePage>
  );
}
