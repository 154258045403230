import { Dropdown, MenuProps } from "antd";
import { FaEye } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { RiDeleteBin6Line, RiEqualizerFill } from "react-icons/ri";
import styles from "./Settings.module.scss";

type TProps = {
  onPreview: () => void;
  onDelete: () => void;
  onDownload: () => void;
};

function Settings({ onPreview, onDelete, onDownload }: TProps) {
  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <FaEye className={styles.icon} />,
      label: "عرض",
      onClick: onPreview,
      style: {
        width: "100px",
      },
    },
    {
      key: "2",
      icon: <FiDownload className={styles.icon} />,
      label: "تنزيل",
      onClick: onDownload,
    },
    {
      type: "divider",
    },
    {
      key: "3",
      icon: <RiDeleteBin6Line className={styles.icon} />,
      label: "حذف",
      onClick: onDelete,
    },
  ];

  return (
    <Dropdown className={styles.dropdown} trigger={["click"]} menu={{ items }}>
      <div className={styles.settings}>
        <RiEqualizerFill />
      </div>
    </Dropdown>
  );
}

export default Settings;
