/** @format */

import SignInLottie from "../../../assets/lottie/lottie-sign-in.json";
import AuthLayout from "../../../components/auth/AuthLayout/AuthLayout";
import SignInForm from "../../../components/auth/Forms/SignInForm/SignInForm";

function Page() {
  document.title = "تسجيل الدخول";
  return (
    <AuthLayout lottie={SignInLottie}>
      <SignInForm formHeading="تسجيل الدخول!" />
    </AuthLayout>
  );
}
export default Page;
