/** @format */

import { Player } from "@lordicon/react";

import { Button, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { GoCheck } from "react-icons/go";
import { IoIosCloseCircle, IoMdClose } from "react-icons/io";
import { IoStorefrontOutline } from "react-icons/io5";

import { BiCartDownload } from "react-icons/bi";

import CartPlayer from "../../../assets/lottie/cart.json";
import OrderSuccess from "../../../assets/lottie/orderSuccess.json";
import { useGetProfile } from "../../../hooks/profile";
import {
  useAddToCart,
  useCart,
  useCreateOrder,
  useDeleteFromCart,
} from "../../../hooks/store";
import { TCartProduct } from "../../../types/products";
import CustomLink from "../../Common/CustomLink/CustomLink";
import Spinner from "../../Common/Spinner";
import ProductOptions from "../Products/ProductOptions";
import style from "./style.module.scss";

export default function Cart() {
  const playerRef1 = useRef<Player>(null);
  const [isOpen, setOpen] = useState({ pay: false, orderSuccess: false });
  const { data: profile } = useGetProfile();
  const { data, isLoading, refetch, isFetching } = useCart();
  const onSuccess = () => {
    setOpen({ pay: false, orderSuccess: true });
  };
  const { mutate, isLoading: isCreateOrderLoading } = useCreateOrder(onSuccess);
  useEffect(() => {
    playerRef1.current?.playFromBeginning();
  }, []);
  return (
    <div className={style.cartCont}>
      {isLoading ? (
        <Spinner />
      ) : data && data.cart_products?.length >= 1 ? (
        <div className={style.cartItems}>
          {data.cart_products.map((product) => (
            <CartProductCard
              refetchCart={refetch}
              key={product.id}
              product={product}
            />
          ))}

          <div className={style.orderSummary}>
            <div className={style.row}>
              <label className={style.title}>عدد البطاقات</label>
              <label>:</label>
              <label className={style.cardsCount}>
                {data.cart_products.length}
              </label>
              <span>بطاقة</span>
            </div>
            <div className={style.row}>
              <label className={style.title}>الإجمالي</label>
              <label>:</label>
              <b>{data.total_price}</b>
              <span> {profile?.currency?.name || "ر.س"}</span>
            </div>
          </div>
          <Button
            size='large'
            onClick={() => {
              setOpen({ ...isOpen, pay: true });
            }}>
            إتمام الطلب
          </Button>

          <Modal
            open={isOpen.pay}
            footer={null}
            onCancel={() => setOpen({ ...isOpen, pay: false })}>
            <div className={style.paymentModal}>
              <Player
                onComplete={() => playerRef1.current?.playFromBeginning()}
                size={80}
                ref={playerRef1}
                icon={CartPlayer}
                colors='primary:#6691e7,secondary:#fb8b02'
              />
              <h3>تأكيد عملية الدفع</h3>
              <div className={style.topupPrice}>
                <span> رصيدك الحالي:</span>
                <p>{Number(profile?.balance).toFixed(2)} </p>
                <span className={style.currency}>
                  {" "}
                  {profile?.currency?.code || "ر.س"}
                </span>
              </div>
              <div className={style.balance}>
                <span>سيتم خصم مبلغ</span>
                <p>{data.total_price}</p>
                <span className={style.currency}>
                  {" "}
                  {profile?.currency?.code || "ر.س"}
                </span>
                <span>من رصيدك</span>
              </div>
              <div>
                <Button
                  loading={isCreateOrderLoading}
                  onClick={() => mutate()}
                  className={style.payNowBtn}
                  color='primary'>
                  ادفع الآن <GoCheck />
                </Button>
                <Button
                  onClick={() => setOpen({ ...isOpen, pay: false })}
                  className={style.cancelBtn}
                  color='default'>
                  إلغاء <IoMdClose />
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <p className={style.emptyCart}>لا يوجد أي منتجات في السلة</p>
      )}
      <Modal
        open={isOpen.orderSuccess}
        footer={null}
        onCancel={() => setOpen({ ...isOpen, orderSuccess: false })}>
        <div className={style.paymentModal}>
          <Player
            onComplete={() => playerRef1.current?.playFromBeginning()}
            size={80}
            ref={playerRef1}
            icon={OrderSuccess}
            colors='primary:#6691e7,secondary:#fb8b02'
          />
          <h3> تم الدفع بنجاح</h3>

          <div>
            <CustomLink to={"/orders"}>
              <Button
                loading={isCreateOrderLoading}
                className={style.payNowBtn}
                color='primary'>
                الذهاب لطلباتي
                <BiCartDownload />
              </Button>
            </CustomLink>
            <Button
              onClick={() => setOpen({ ...isOpen, orderSuccess: false })}
              className={style.cancelBtn}
              color='default'>
              متابعة التسوق <IoStorefrontOutline />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
const CartProductCard = ({
  product,
  refetchCart,
}: {
  product: TCartProduct;
  refetchCart: () => void;
}) => {
  const [count, setCount] = useState<number>(1);
  const [optionsValues, setValues] = useState<
    Record<string, string | number[]>
  >({});
  const onSuccess = () => {
    refetchCart();
  };
  const { data: profile } = useGetProfile();
  const { mutate } = useAddToCart(onSuccess);
  const { mutate: deleteProduct, isLoading } = useDeleteFromCart(onSuccess);

  const onOptionChange = (key: number, value: string | number[]) => {
    setValues({ ...optionsValues, [key.toString()]: value });
    handleSave({ ...optionsValues, [key.toString()]: value });
  };
  useEffect(() => {
    if (product?.options.length) {
      const values = product?.options;
      let optionsValuesInit = {};

      values.forEach((value) => {
        optionsValuesInit = {
          ...optionsValuesInit,
          [value.product_option_id]: value.value
            ? value.value
            : value.cart_product_option_values.map(
                (item) => item.option_value.id
              ),
        };
      });

      setValues(optionsValuesInit);
    } else {
      setCount(product.quantity);
    }
  }, [product?.options, product.quantity]);
  const handleSave = async (values: any) => {
    const res = await mutate({
      product_id: product.product.id,
      category_id: product.category_id,
      quantity: 1,
      product_options: Object.keys({ ...values }).map((key) => {
        return {
          id: Number(key),
          ...(typeof values[key] === "string"
            ? { value: values[key] }
            : { option_value_ids: values[key] }),
        };
      }),
    });
  };
  const handleCountChange = async (count: number) => {
    setCount(count);
    const res = await mutate({
      product_id: product.product.id,
      category_id: product.category_id,
      quantity: count,
    });
  };
  return (
    <div className={style.item}>
      <img src={product.product.image} alt={"product"} />
      <div className={style.info}>
        <h6> {product.product.name} </h6>
        {product?.options.length ? (
          <ProductOptions
            options={product.product.product_options}
            productId={product.product.id}
            values={product.options}
            optionsValues={optionsValues}
            onOptionChange={onOptionChange}
            price={product.product.price}
            wholesale_price={product.product.wholesale_price}
          />
        ) : (
          <div className={style.countAndTotal}>
            <div className={style.count}>
              <button
                disabled={count === 1}
                onClick={() => handleCountChange(count - 1)}>
                -
              </button>

              <input
                value={count}
                type={"number"}
                onChange={(event) =>
                  handleCountChange(Number(event.target.value))
                }
              />
              <button onClick={() => handleCountChange(count + 1)}>+</button>
            </div>
            <p>
              <b>{(count * product.product.wholesale_price).toFixed(3)} </b>
              {profile?.currency?.code || "ر.س"}
            </p>
          </div>
        )}
      </div>
      {isLoading ? (
        <Spin className={style.closeBtn} />
      ) : (
        <IoIosCloseCircle
          onClick={() =>
            deleteProduct({
              product_id: product.product.id,
              category_id: product.category_id,
            })
          }
          className={style.closeBtn}
        />
      )}
    </div>
  );
};
