import { TableProps, Tag } from "antd";
import { useMemo } from "react";
import { productStatus } from "../../../constant/ProductType";
import formateDate from "../../../utils/formateDate.";
import CustomTable from "../../Common/CustomTable/CustomTable";
import styles from "./ProductSalesTable.module.scss";

type TProps = {
  data?: any;
  isLoading?: boolean;
  handlePagination: (page: number) => void;
};

function ProductSalesTable({ data, isLoading, handlePagination }: TProps) {
  const columns: TableProps<any>["columns"] = useMemo(
    () => [
      {
        title: "التاريخ",
        render: (_, record) => (
          <p>{formateDate(record.created_at, "YYYY-MM-DD")}</p>
        ),
      },

      {
        title: "اسم المنتج",
        dataIndex: "product_name",
        key: "product_name",
        minWidth: 250,
      },
      {
        title: "العلامة التجارية",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: " النوع",
        dataIndex: "type",
        key: "type",
        render: (val) => (
          <Tag
            className={styles.tag}
            color={productStatus[val as keyof typeof productStatus]?.color}
          >
            {productStatus[val as keyof typeof productStatus]?.label}{" "}
          </Tag>
        ),
      },
      {
        title: "الكمية	",
        dataIndex: "qty",
        key: "qty",
      },
      {
        title: "سعر الوحدة	",
        dataIndex: "unit_price",
        key: "unit_price",
      },
      {
        title: " إجمالي السعر		",
        dataIndex: "total_before_tax",
        key: "total_before_tax",
      },
      {
        title: "  الضريبة على الوحدة			",
        dataIndex: "calc_tax_amount",
        key: "calc_tax_amount",
        render: (val) => <p>{Number(val).toFixed(2)}</p>,
      },
      {
        title: "الإجمالي بعد الضريبة",
        dataIndex: "total_after_tax",
        key: "total_after_tax",
        render: (val) => <b>{Number(val).toFixed(2)}</b>,
      },
    ],
    []
  );

  return (
    <div className={styles.productSalesTable}>
      <CustomTable
        columns={columns}
        className={styles.customTable}
        dataSource={data?.data}
        configStyle={{
          fontWeightStrong: 500,
          headerColor: "#878A99",
          padding: 10,
        }}
        loading={isLoading}
        pagination={{
          showSizeChanger: false,
          current: data?.current_page,
          pageSize: data?.per_page,
          total: data?.total,
          onChange: handlePagination,
        }}
      />
    </div>
  );
}

export default ProductSalesTable;
