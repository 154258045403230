/** @format */

import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { FaRegImage } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import style from "./style.module.scss";
export default function ImageUploader({
  setImage,
  image,
  className,
  addImageBtnClassName,
  avatarIcon = <FaRegImage />,
}: {
  setImage: (image: string | null, file: File) => void;
  image: string | null;
  className?: string;
  avatarIcon?: ReactNode;
  addImageBtnClassName?: string;
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleAddImageClick = () => {
    fileInputRef.current?.click();
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setImage(reader.result, file); // Set image preview URL
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
    }
  };
  return (
    <div className={clsx(style.imgCont, className)}>
      {image ? <img src={image} alt="Preview" /> : avatarIcon}

      <div
        className={clsx(style.addImageBtn, addImageBtnClassName)}
        onClick={handleAddImageClick}
      >
        <GoPlus />
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </div>
  );
}
